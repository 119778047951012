// import React, { FC, useEffect, useState } from "react";
// import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
// import StayCardH from "components/StayCardH/StayCardH";
// import PropertyCard from "components/listingCardOfProperty/card";

// import GoogleMapReact from "google-map-react";
// import { DEMO_STAY_LISTINGS } from "data/listings";
// import ButtonClose from "shared/ButtonClose/ButtonClose";
// import Checkbox from "shared/Checkbox/Checkbox";
// import Pagination from "shared/Pagination/Pagination";
// import TabFilters from "./TabFilters";
// import Heading2 from "components/Heading/Heading2";
// import PropertyCardH from "components/PropertyCardH/PropertyCardH";
// import { Card, CardContent, Typography, Box } from '@mui/material';
// const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

// export interface SectionGridHasMapProps { }

// const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
//   const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
//   const [showFullMapFixed, setShowFullMapFixed] = useState(false);
//   const useIsSmallScreen = (breakpoint = 780) => {
//     const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < breakpoint);
//     console.log("SssssssssssssSS11111", window.innerWidth)
//     useEffect(() => {
//       const handleResize = () => setIsSmallScreen(window.innerWidth < breakpoint);
//       window.addEventListener('resize', handleResize);

//       return () => window.removeEventListener('resize', handleResize);
//     }, [breakpoint]);

//     return isSmallScreen;
//   };

//   const [isDragging, setIsDragging] = useState(false);
//   const [startY, setStartY] = useState(0);
//   const [currentY, setCurrentY] = useState(0);
//   const [translateY, setTranslateY] = useState(100); // Start hidden
//   const [velocity, setVelocity] = useState(0); // Track the dragging velocity

//   // Handle touch start event
//   const handleTouchStart = (event:any) => {
//     setIsDragging(true);
//     setStartY(event.touches[0].clientY);
//     setVelocity(0); // Reset velocity on new drag
//   };

//   // Handle touch move event
//   const handleTouchMove = (event:any) => {
//     if (!isDragging) return;
//     const touchY = event.touches[0].clientY;
//     const deltaY = touchY - startY;

//     // Gradual movement and restrict upward dragging
//     const newTranslateY = Math.max(translateY + deltaY / 2, 0); // Use deltaY/2 for smoother drag
//     setTranslateY(newTranslateY);
//     setCurrentY(touchY);

//     // Track velocity to determine if it's a fast drag
//     const dragVelocity = (touchY - startY) / (event.timeStamp || 1);
//     setVelocity(dragVelocity);
//   };

//   // Handle touch end event
//   const handleTouchEnd = () => {
//     setIsDragging(false);

//     // Decide if the card should snap up or back down based on drag amount
//     if (translateY < 50 || velocity < -0.5) {
//       setTranslateY(0); // Snap to the top if dragged far enough or dragged quickly
//     } else {
//       setTranslateY(100); // Snap back to the bottom
//     }
//   };
//   const isSmallScreen1 = useIsSmallScreen();
//   return (
//     <div>
//       <div style={{
//         width: "100%", background: "black", backgroundImage: "url(image_2024_10_01T08_55_21_065Z.png)", backgroundSize: "cover",
//         backgroundRepeat: "no-repeat", backgroundAttachment: "fixed"
//       }} className="relative flex min-h-screen">

//         <div
//           className={`xl:flex-grow xl:static xl:block `}
//         // ${
//         //   showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
//         // }
//         >
//           {showFullMapFixed && (
//             <ButtonClose
//               onClick={() => setShowFullMapFixed(false)}
//               className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
//             />
//           )}

//           <div className="mapPosition fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh)] rounded-md overflow-hidden">
//             {/* <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
//               <Checkbox
//                 className="text-xs xl:text-sm"
//                 name="xx"
//                 label="Search as I move the map"
//               />
//             </div> */}

//             {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
//             <GoogleMapReact
//               defaultZoom={12}
//               defaultCenter={DEMO_STAYS[0].map}
//               bootstrapURLKeys={{
//                 key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY",
//               }}
//               yesIWantToUseGoogleMapApiInternals
//               options={{
//                 gestureHandling: 'cooperative',
//                 scrollwheel: true,
//                 fullscreenControl: false,
//                 mapTypeId: 'roadmap',
//               }}
//             >
//               {DEMO_STAYS.map((item) => (
//                 <AnyReactComponent
//                   isSelected={currentHoverID === item.id}
//                   key={item.id}
//                   lat={item.map.lat}
//                   lng={item.map.lng}
//                   listing={item}
//                 />
//               ))}
//             </GoogleMapReact>
//           </div>
//         </div>
//         {/* CARDSSSS */}
//         <div className="z-[3] scro overflow-auto h-screen sticky top-0">
//         {/* <Card className="max-w-md mx-auto shadow-lg">
//       <CardContent className="overflow-auto max-h-64">

//       </CardContent>
//     </Card> */}
//           {isSmallScreen1 ?
//             <>
//               <div className={`boxShadow min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8  hidden-card1 ${translateY === 0 ? "visible-card" : ""}`}
//                 style={{
//                   transform: `translateY(${translateY}%)`,
//                   overflow:"auto",
//                 }}
//                 onTouchStart={handleTouchStart}
//                 onTouchMove={handleTouchMove}
//                 onTouchEnd={handleTouchEnd}
//               //  className=" boxShadow min-h-screen w-full xl:w-[780px] 2xl:w-[880px] flex-shrink-0 xl:px-8 "
//               >
//                 <div className="grid grid-cols-1 gap-8">
//                   {DEMO_STAYS.map((item) => (
//                     <div
//                       key={item.id}
//                       onMouseEnter={() => setCurrentHoverID((_) => item.id)}
//                       onMouseLeave={() => setCurrentHoverID((_) => -1)}
//                     >
//                       <PropertyCardH data={item} />
//                     </div>
//                   ))}
//                 </div>
//                 <div className="flex mt-16 justify-center items-center">
//                   <Pagination />
//                 </div>
//               </div>
//             </> :
//             <>
//               <div className="hidden-card" >
//               </div>
//               <div className="boxShadow min-h-screen w-full xl:w-[720px] 2xl:w-[800px] flex-shrink-0">
//                 <div className="z-10 bg-white fixed xl:sticky top-0 xl:top-[60px] xl:px-8 left-0 w-full  xl:h-[calc(100vh-88vh)] rounded-md overflow-hidden mb-8 mt-8 lg:mb-11">
//                   <TabFilters />
//                 </div>
//                 <div className="grid xl:grid-cols-2 grid-cols-1 xl:px-8 gap-8">
//                   {DEMO_STAYS.map((item) => (
//                     <div
//                       key={item.id}
//                       onMouseEnter={() => setCurrentHoverID((_) => item.id)}
//                       onMouseLeave={() => setCurrentHoverID((_) => -1)}
//                     >
//                       <PropertyCard data={item} />
//                     </div>
//                   ))}

//                 </div>
//                 <div className="flex mt-16 mb-8 justify-center items-center">
//                   <Pagination />
//                 </div>
//               </div>
//             </>
//           }
//         </div>

//         {!showFullMapFixed && (
//           <div
//             className="flex xl:hidden items-center justify-center fixed bottom-8 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
//             onClick={() => setShowFullMapFixed(true)}
//           >
//             <i className="text-lg las la-map"></i>
//             <span>Show map</span>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default SectionGridHasMap;


import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { debounce } from 'lodash';
import AnyPropertyReactComponentProps from "components/AnyReactComponent/AnyPropertyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import PropertyCard from "components/listingCardOfProperty/card"
import { Card, CardContent, Typography, Box } from '@mui/material';
import axios from "axios";
import { useAppContext } from "AppContext";
import CustomPagination from "shared/Pagination/CustomPagination";
import { base_url } from "constant/constant";
import TabFiltersNew from "./TabFilterNew";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import moment from "moment";


const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export interface SectionGridHasMapProps { }

declare namespace google {
  namespace maps {
    // Add your specific type declarations here if needed
    export class Map { }
  }
}

interface Coordinates {
  type: 'Point'; // Enforce the 'Point' enum value
  coordinates: [longitude: number, latitude: number]; // Tuple for fixed two-element array
}
interface Amenity {
  name: string;
}
export interface Property {
  _id: string;
  title: string;
  residence_type: string;
  city: string;
  country: string;
  base_price: number;
  sleeps: number;
  bedrooms: number;
  bathrooms: number;
  createdAt: string;
  updatedAt: string;
  coordinates: Coordinates;
  toilets: any;
  amenities: Amenity[];
  policies: any;
  avgRatePerDay: number;
  // coordinates

}

export interface ApiResponse {
  title: string;
  error: boolean;
  data: Property[];
  total: number;
  page: number;
  pages: number;
}

const SectionGridHasMap: FC<SectionGridHasMapProps> = () => {
  const [currentHoverID, setCurrentHoverID] = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed] = useState(false);
  const [centerLocation, setCenterLocation] = useState({ "lat": 48.8566, "lng": 2.3522 });
  const [properties, setProperties] = useState<Property[]>([]);
  const [filterproperties, setFilterProperties] = useState<Property[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const mapRef = useRef<google.maps.Map | null>(null);
  const [rangePrices, setRangePrices] = useState([0, 1000]);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const useIsSmallScreen = (breakpoint = 780) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < breakpoint);
    useEffect(() => {
      const handleResize = () => setIsSmallScreen(window.innerWidth < breakpoint);
      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
    }, [breakpoint]);

    return isSmallScreen;
  };
  const smallScreen1 = useIsSmallScreen()

  //   const handleSetCenterLocation = useCallback((lat: number, lng: number) => {
  //     setCenterLocation({ lat, lng });
  // }, []);

  const getPaginatedProperties = () => {
    const startIndex = (currentPage - 1) * 10;  // Start index for the current page
    const endIndex = startIndex + 10;           // End index for the current page
    return filterproperties.slice(startIndex, endIndex);  // Slice the array based on the current page
  };
  const handleMarkerClick = (markerLat: number, markerLng: number) => {
    const mapBounds = {
      north: centerLocation.lat + 0.05, // Change these values based on zoom level
      south: centerLocation.lat - 0.05,
      east: centerLocation.lng + 0.05,
      west: centerLocation.lng - 0.05,
    };

    let latOffset = 0;
    let lngOffset = 0;

    // Calculate latitude offset based on north/south proximity
    if (markerLat >= mapBounds.north) {
      latOffset = -0.05; // Move down if too close to the north
    } else if (markerLat <= mapBounds.south) {
      latOffset = 0.05; // Move up if too close to the south
    }

    // Calculate longitude offset based on east/west proximity
    if (markerLng >= mapBounds.east) {
      lngOffset = -0.05; // Move left if too close to the east
    } else if (markerLng <= mapBounds.west) {
      lngOffset = 0.05; // Move right if too close to the west
    }

    setCenterLocation({ lat: markerLat + latOffset, lng: markerLng + lngOffset });
  };
  const handleSetCenterLocation = (lat: any, lng: any) => {
    handleMarkerClick(lat, lng);
  }
  const { city, filterState, updateFilterState, filterValues, setFilterValues,setResetFilter, resetFilter } = useAppContext()

  const cityCoordinates = [
    { lat: 50.85034, lng: 4.35171 },//city: "Brussels",
    { lat: 48.864716, lng: 2.349014 } //city: "Paris", 
  ];

  const formattedFilterState = {
    ...filterState,
    checkInDate: filterState?.checkInDate ? moment(filterState?.checkInDate).format("YYYY-MM-DD") : null,
    checkOutDate: filterState?.checkOutDate ? moment(filterState?.checkOutDate).format("YYYY-MM-DD") : null,
  };
  const fetchProperties = async (values: any) => {
    try {
      const pData = {
        ...values,
        ...formattedFilterState,
        city: city,
        page: currentPage,
        limit: 10
      }
      setLoading(true);
      sessionStorage.setItem('filterData', JSON.stringify(pData));
      const response = await axios.get<ApiResponse>(`${base_url}/property/website_property_listing`, {
        params: pData,
      });
      updateFilterState("handleSubmit", false)
      if (response.data.error) {
        setSnackbarSeverity('error');
        setSnackbarMessage(response.data.title);
        return setSnackbarOpen(true);
      }
      const updatedProperties = response.data.data.map((property: any) => {
        const propertyImage = property.image;
        const galleryImages = property.galleryImgs || [];

        const updatedGalleryImages = [
          ...(propertyImage ? [propertyImage] : []),
          ...galleryImages,
        ];

        return { ...property, galleryImgs: updatedGalleryImages };
      });

      setProperties(updatedProperties);
      setFilterProperties(updatedProperties)
      setTotalPages(Math.ceil(updatedProperties.length / 10));
      if (filterState?.location == "Paris") {
        setCenterLocation({ lat: 48.864716, lng: 2.349014 });
      } else {
        setCenterLocation({ lat: 50.85034, lng: 4.35171 });
      }
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to fetch properties');
    } finally {
      setLoading(false);
      updateFilterState("handleSubmit", false)
    }
  };


  const applyFilters = (filterValues: any) => {
    const rangePrices = filterValues.rangePrices
    const filteredProperties = properties.filter((property) => {
      const matchesToilets = filterValues.toilets === 0 || property.toilets >= filterValues.toilets;
      const matchesBedrooms = filterValues.bedrooms === 0 || property.bedrooms >= filterValues.bedrooms;
      const matchesBathrooms = filterValues.bathrooms === 0 || property.bathrooms >= filterValues.bathrooms;

      const matchesPropertyType = filterValues.propertyType.length === 0 || filterValues.propertyType.includes(property.residence_type);
      let matchesPriceRange
      if (property.avgRatePerDay) {
        matchesPriceRange = property.avgRatePerDay >= rangePrices[0] && property.avgRatePerDay <= rangePrices[1];
      } else {
        matchesPriceRange = property.base_price >= rangePrices[0] && property.base_price <= rangePrices[1];
      }

      return (
        matchesToilets &&
        matchesBedrooms &&
        matchesBathrooms &&
        matchesPropertyType &&
        matchesPriceRange
      );
    });

    setFilterProperties(filteredProperties);
    setTotalPages(Math.ceil(filteredProperties.length / 10));
  };

  const getStoredFilterValue = (): any => {
    const storedFilters = sessionStorage.getItem("filterValues");
    return storedFilters
      ? JSON.parse(storedFilters)
      : {
        toilets: 0,
        bedrooms: 0,
        bathrooms: 0,
        propertyType: [],
        rangePrices:[0,1000]
        };
  };


  useEffect(()=>{
    let value =  getStoredFilterValue()
    if(value){
      applyFilters(value)
    }
  },[loading])

  useEffect(() => {
    const storedFilters = sessionStorage.getItem("selectedFilters");
    if (storedFilters) {
      setSelectedFilters(JSON.parse(storedFilters));
    }
  }, [totalPages]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const sortProperties = (filters: string[]) => {
    let sortedProperties = [...filterproperties]
    sortedProperties = sortedProperties.sort((a: any, b: any) => a.rank - b.rank);
    if (filters?.includes("Pets Allowed")) {
      sortedProperties = sortedProperties.sort((a: any, b: any) => {
        const aAllowsPets = a?.policies?.includes("Pets allowed") ? 1 : 0;
        const bAllowsPets = b?.policies?.includes("Pets allowed") ? 1 : 0;

        return bAllowsPets - aAllowsPets;
      });
    }

    if (filters?.includes('Family friendly')) {
      sortedProperties = sortedProperties.sort((a: any, b: any) => {
        const aAllowsPets = a?.accessibility?.includes('Family friendly') ? 1 : 0;
        const bAllowsPets = b?.accessibility?.includes('Family friendly') ? 1 : 0;

        return bAllowsPets - aAllowsPets;
      });
    }
    if (filters?.includes('Pets friendly')) {
      sortedProperties = sortedProperties.sort((a: any, b: any) => {
        const aAllowsPets = a?.accessibility?.includes('Pets friendly') ? 1 : 0;
        const bAllowsPets = b?.accessibility?.includes("Pets friendly") ? 1 : 0;

        return bAllowsPets - aAllowsPets;
      });
    } if (filters?.includes('Middle term stay')) {
      sortedProperties = sortedProperties.sort((a: any, b: any) => {
        const aAllowsPets = a?.accessibility?.includes('Middle term stay') ? 1 : 0;
        const bAllowsPets = b?.accessibility?.includes("Middle term stay") ? 1 : 0;

        return bAllowsPets - aAllowsPets;
      });
    }
    setFilterProperties(sortedProperties);
  };

  useEffect(() => {
    sortProperties(selectedFilters)
  }, [selectedFilters])


  useEffect(() => {
    if (filterState?.handleSubmit) {
      fetchProperties(filterValues);
    }
  }, [filterState?.handleSubmit]);


  useEffect(() => {
    fetchProperties(filterValues);
  }, []);


  const handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
    const scrollContainer = document.querySelector('.overflow-y-auto');
    if (scrollContainer) {
      scrollContainer.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };


  const mapStyles4 = [
    {
      "elementType": "geometry",
      "stylers": [{ "color": "#e0e0e0" }] // Light gray background
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#616161" }] // Dark gray text
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [{ "color": "#f5f5f5" }] // White text stroke
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [{ "color": "#add8e6" }] // Light blue for water
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [{ "color": "#f9f9f9" }] // Light gray for general land
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [{ "color": "#c5e1a5" }] // Light green for parks
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [{ "color": "#d6d6d6" }] // Gray for points of interest
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [{ "color": "#ffffff" }] // White roads
    }
  ];
  const mapStyles = [
    {
      "elementType": "geometry",
      "stylers": [{ "color": "#F6F6F4" }]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#523735" }]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [{ "color": "#f5f1e6" }]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [{ "color": "#D0ECEF" }]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [{ "color": "#F6F6F4" }]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [{ "color": "#E2F2DA" }]
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [{ "color": "#ffffff" }]
    },
    {
      "featureType": "administrative",
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#444444" }]
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [
        {
          color: "#e0e0e0",
        },
      ],
    },
    { "elementType": "labels.text.fill", "stylers": [{ "color": "#4a4a4a", "fontWeight": "bold" }] },
  ]

  const mapStyles2 = [
    {
      "elementType": "geometry",
      "stylers": [{ "color": "#f5f5f5" }]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#616161" }]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [{ "color": "#f5f5f5" }]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [{ "color": "#e4c7c7" }] // Light red for borders
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#d2a600" }] // Yellow text
    },
    {
      "featureType": "landscape.man_made",
      "elementType": "geometry",
      "stylers": [{ "color": "#eaeaea" }]
    },
    {
      "featureType": "landscape.natural",
      "elementType": "geometry",
      "stylers": [{ "color": "#d5f5d1" }] // Light green parks/vegetation
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [{ "color": "#eeeeee" }]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [{ "color": "#bdecb6" }] // Light green parks
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [{ "color": "#ffffff" }]
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#757575" }]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [{ "color": "#dadada" }]
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#616161" }]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [{ "color": "#e5e5e5" }]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [{ "color": "#c9c9c9" }]
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [{ "color": "#9e9e9e" }]
    }
  ]

  const mapStyles1 = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#ffffff" }],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: "#c9e7ff" }],
    },
    {
      featureType: "transit",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [{ color: "#eaeaea" }],
    },
  ];

  const handleApiLoaded = ({ map }: { map: google.maps.Map }) => {
    mapRef.current = map as google.maps.Map;
  };

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div style={{
        width: "100%", background: 'linear-gradient(rgb(232, 238, 237) 0%, rgba(232, 238, 237, 0) 100%)', backgroundSize: "cover",
        backgroundRepeat: "no-repeat", backgroundAttachment: "fixed"
      }} className="relative flex min-h-screen">

        <div
          className={`xl:flex-grow xl:static xl:block ${showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
            }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className=" map-container fixed xl:sticky top-0 xl:top-[88px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* <div className="absolute bottom-5 left-3 lg:bottom-10 lg:bottom-3 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white dark:bg-neutral-800 shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div> */}
            <div className="graphCard" />
            <GoogleMapReact
              defaultZoom={13}
              defaultCenter={filterState?.location == "Paris" ? cityCoordinates[1] : cityCoordinates[0]}
              center={centerLocation}
              onGoogleApiLoaded={handleApiLoaded}
              bootstrapURLKeys={{
                key: "AIzaSyDE4YXJP-Oq9uXDySWbJBOBtwej5bdrw1A"
                // key: "AIzaSyBdYeVetW8SdWB7Tkfyu7tfMf2NiMgDh-M"
                // key: "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY"//"AIzaSyDE4YXJP-Oq9uXDySWbJBOBtwej5bdrw1A" // "AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY" //",//
              }}
              yesIWantToUseGoogleMapApiInternals
              options={{
                styles: mapStyles1,
                gestureHandling: 'cooperative',
                scrollwheel: false,
                fullscreenControl: false,
                mapTypeId: 'roadmap',
                disableDefaultUI: true,
                zoomControl: true, 
              }}
            >

              {getPaginatedProperties().map((item) => {
                const coordinates = item.coordinates?.coordinates;
                if (!coordinates || coordinates.length < 2) {
                  return null;
                }

                return (
                  <AnyPropertyReactComponentProps
                    isSelected={currentHoverID === item._id}
                    key={item._id}
                    lat={coordinates[0]}
                    lng={coordinates[1]}
                    base_price={item.avgRatePerDay ? item.avgRatePerDay :item.base_price}
                    listing={item}
                    handleSetCenterLocation={handleSetCenterLocation}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
        </div>
        {/* CARDSSSS */}
        <div className="min-h-screen w-full xl:w-[50%] 2xl:w-[50%] flex-shrink-0 xl:pr-6 xl:mt-6">
          <Card style={{ borderRadius: !smallScreen1 ? "1rem" : '0rem' }} className="shadow-lg">
            <CardContent style={{ padding: "0px 16px 16px 16px" }} className="overflow-y-auto max-h-[100vh] xl:max-h-[95vh]">
              <div className="bg-white pb-2 xl:sticky z-[3] top-0 xl:top-[0px] left-0 w-full  h-full  overflow-hidden  py-5">
                <TabFiltersNew count={filterproperties.length} rangePrices={rangePrices} setRangePrices={setRangePrices} selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} fetchProperties={applyFilters} filterValues={filterValues} setFilterValues={setFilterValues} />
              </div>
              <div className={`grid gap-8 ${smallScreen1 ? 'grid-cols-1' : "grid-cols-2"}`}>
                {getPaginatedProperties().map((item: any) => (
                  <div
                    key={item._id}
                    onMouseEnter={() => setCurrentHoverID((_) => item._id)}
                    onMouseLeave={() => setCurrentHoverID((_) => -1)}
                  >
                    {/* {smallScreen1 ? <StayCardH data={item} /> : <PropertyCard id={item._id} data={item} />} */}
                    <PropertyCard id={item._id} data={item} />
                  </div>
                ))}
              </div>
              <div className="flex mt-16 justify-center items-center">
                <Stack spacing={2} sx={{ marginTop: 3 }}>
                  <Pagination
                    count={Math.ceil(filterproperties.length / 10)}
                    page={currentPage} // Current page
                    onChange={handlePageChange} // Triggered when user interacts
                    variant="outlined"
                    color="primary"
                    siblingCount={1} // Number of pages to show before/after current page
                    boundaryCount={1} // Number of pages to show at the start and end
                  />
                </Stack>
                {/* <CustomPagination/> */}
                {/* <CustomPagination
                  currentPage={currentPage}
                  // pageSize={pageSize}
                  totalPages={totalPages}
                  onPageChange={handlePageChange} // Pass callback to update page
                /> */}
              </div>
            </CardContent>
          </Card>

        </div>


        {!showFullMapFixed && (
          <div
            className="flex xl:hidden items-center justify-center fixed bottom-12 left-1/2 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )}
        <div className="mapEndLine" />
      </div>
    </div>
  );
};

export default SectionGridHasMap;
