import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date }) => {
  // Example: Highlight today's date
  const isToday = date && new Date().toDateString() === date.toDateString();
// hightLight for todaye  ${
//     isToday ? "bg-blue-500 text-white rounded-full" : ""
//   }
  return (
    <span
      className={`react-datepicker__day_span`}
    >
      {dayOfMonth}
    </span>
  );
};

export default DatePickerCustomDay;
