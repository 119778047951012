import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MyBookings from "./MyBookings";

const stripePromise = loadStripe("pk_test_51LNrejDyU6gle3xM33h6LiV12yo5gaplmONVwkI8rI0Yiuh6Tc7N1Y1eylTduculJrD591qrFdCokLTQBy4M3Lyd00LmELLjR5");

const BookingApp: React.FC = () => {
    return (
        // <Elements stripe={stripePromise}>
            <MyBookings />
        // </Elements>
    );
};

export default BookingApp;