import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppContext } from "AppContext";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "w-28",
}) => {
  // !["/my-bookings"].includes(window.location.pathname)
  const location = useLocation();
  const navigate = useNavigate();
  const { filterState, setFilterState, setResetFilter } = useAppContext(); // Access context

  const getStoredFilterState = (): any => {
    const storedFilters = sessionStorage.getItem("filterData");
    return storedFilters
      ? JSON.parse(storedFilters)
      : {
          checkInDate: null,
          checkOutDate: null,
          guestNumber: 1,
          number_of_children: 0,
          number_of_infants: 0,
          location: "Paris",
          handleSubmit: false,
        };
  };

  const handleClick = (event: any) => {
    const storedFilterState = sessionStorage.getItem("filterState") || "";
      
    if (location.pathname === "/") {
      event.preventDefault();
      setFilterState({
        checkInDate: null,
        checkOutDate: null,
        guestNumber: 1,
        number_of_children: 0,
        number_of_infants: 0,
        location: "Paris",
        handleSubmit: false,
      });
      sessionStorage.removeItem("filterData");
      sessionStorage.removeItem("filterValues");
      sessionStorage.removeItem("selectedFilters");
      setResetFilter(true);
      window.location.reload()
    } else {
      navigate("/");
      setFilterState(getStoredFilterState());
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >
      <img style={{ width: '140px', maxWidth: "150px" }} width='138' height={36} src="/logo/europeaLogo.png">
      </img>
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {/* {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </button>
  );
};

export default Logo;
