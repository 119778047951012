import React, { Fragment, useState, FC, useEffect } from "react";
import { Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import ClearDataButton from "../ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import useOutsideAlerter from "hooks/useOutsideAlerter";
import { useAppContext } from "AppContext";
import moment from 'moment'

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[ nc-hero-field-padding--small ]",
}) => {

  const { updateFilterState, filterState } = useAppContext();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  //
  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(refContainer, () => setIsOpen(false));
  
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    if (start === null && end === null) {
      setStartDate(null);
      setEndDate(null);
      updateFilterState("checkInDate", null)
      updateFilterState("checkOutDate", null)
    }
    else {
      const startDate = moment(start).format("YYYY-MM-DD")
      const endDate = moment(end).format("YYYY-MM-DD")
      updateFilterState("checkInDate", startDate)
      updateFilterState("checkOutDate", endDate)
      setStartDate(start);
      setEndDate(end);
      if (start && end) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    if (filterState?.checkInDate && filterState?.checkOutDate) {
      setStartDate(new Date(filterState.checkInDate)); 
      setEndDate(new Date(filterState.checkOutDate));
    }else{
      setStartDate(null);
      setEndDate(null);
    }
  }, []); 
  
  const renderInput = () => {
    return (
      <>
        <div style={{ whiteSpace: "nowrap" }} className="flex-grow text-left">
          <span style={{ color: "#0c586e" }} className="block xl:text-base font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
              endDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
              })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light whitespace-nowrap">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };

  return (
    <div
      ref={refContainer}
      className={`StayDatesRangeInput z-10 relative flex ${className}`}
    >
      <>
        <div
          className={`flex-1 z-10 flex relative ${fieldClassName} items-center space-x-3 focus:outline-none ${isOpen ? "nc-hero-field-focused--2" : ""
            }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {renderInput()}
          {startDate && isOpen && (
            <ClearDataButton onClick={() => onChangeDate([null, null])} />
          )}
        </div>

        {isOpen && (
          <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5 bg-white dark:bg-neutral-800"></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
          show={isOpen}
        >
          <div className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
            <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
              <DatePicker
                selected={startDate}
                onChange={onChangeDate}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                monthsShown={2}
                showPopperArrow={false}
                inline
                renderCustomHeader={(p) => (
                  <DatePickerCustomHeaderTwoMonth {...p} />
                )}
                renderDayContents={(day, date) => (
                  <DatePickerCustomDay dayOfMonth={day} date={date} />
                )}
              />
            </div>
          </div>
        </Transition>
      </>
    </div>
  );
};

export default StayDatesRangeInput;
