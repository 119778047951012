import Glide from "@glidejs/glide";
import useNcId from "hooks/useNcId";
import React, { FC, useEffect, useMemo, useRef } from "react";
import NcImage from "shared/NcImage/NcImage";
import NextPrev from "shared/NextPrev/NextPrev";
import { Link } from "react-router-dom";
import { useAppContext } from "AppContext";

export interface GallerySliderProps {
  className?: string;
  galleryImgs: string[];
  ratioClass?: string;
  uniqueID: string;
  href?: string;
}

const GallerySlider: FC<GallerySliderProps> = ({
  className = "",
  galleryImgs,
  ratioClass = "aspect-w-16 aspect-h-9",
  uniqueID = "uniqueID",
  href = "/listing-stay-detail",
}) => {
  const UNIQUE_CLASS = `gallerySlider__${uniqueID}` + useNcId();
  // let MY_GLIDEJS = useMemo(() => {
  //   return new Glide(`.${UNIQUE_CLASS}`, {
  //     perView: 1,
  //     gap: 0,
  //     keyboard: false,
  //   });
  // }, [UNIQUE_CLASS]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     MY_GLIDEJS?.mount();
  //   }, 10);
  // }, [MY_GLIDEJS, UNIQUE_CLASS, galleryImgs]);

  const glideRef = useRef<Glide | null>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const element = document.querySelector(`.${UNIQUE_CLASS}`);
      if (!element) {
        console.error(`Element with class ${UNIQUE_CLASS} not found.`);
        return;
      }
  
      glideRef.current = new Glide(`.${UNIQUE_CLASS}`, {
        perView: 1,
        gap: 0,
        keyboard: false,
      });
  
      glideRef.current.mount();
    }, 10);
  
    return () => {
      clearTimeout(timeout);
      if (glideRef.current) {
        glideRef.current.destroy();
      }
    };
  }, [UNIQUE_CLASS, galleryImgs]);
    const { filterState } = useAppContext();
  
  const renderDots = () => {
    return (
      <div
        className="glide__bullets flex items-center justify-center absolute bottom-2 left-1/2 transform -translate-x-1/2 space-x-1.5"
        data-glide-el="controls[nav]"
      >
        {galleryImgs.map((_, i) => (
          <button
            className="glide__bullet w-1.5 h-1.5 rounded-full bg-neutral-300"
            key={i}
            data-glide-dir={`=${i}`}
          />
        ))}
      </div>
    );
  };

  const onMarkerClick = (e:any) => {
    e.stopPropagation(); // Prevent the map from handling the click event
    window.open(`/listing-stay-detail?id=${uniqueID}&start_date=${filterState?.checkInDate}&number_of_guests=${filterState?.guestNumber}&end_date=${filterState?.checkOutDate}&number_of_children=${filterState?.number_of_children}&number_of_infants=${filterState?.number_of_infants}`, "_blank"); // Open link in a new tab
  };
  const getImageVariantUrl = (baseUrl:any, variantSuffix:any) => {
    return baseUrl.replace(/(\.[\w\d_-]+)$/i, `${variantSuffix}$1`);
};

  const renderSliderGallery = () => {
    return (
      <div className={`${UNIQUE_CLASS} relative group overflow-hidden`}>
        <div className="glide__track" data-glide-el="track">
          <ul className="glide__slides">
            {galleryImgs.map((item, index) => (
              <li key={index} className="glide__slide">
                <a
                style={{cursor:"pointer"}}
                onClick={onMarkerClick}
                  // href={"/listing-stay-detail"} // Use the href passed as a prop
                  // target="_blank" // Open in new tab
                  rel="noreferrer"
                  className={`block ${ratioClass}`}
                >
                  <NcImage str={getImageVariantUrl(item,"_480") } src={item?item:"/sly_0470-sly_0472.jpg"} />
                </a>
              </li>
            ))}
          </ul>
        </div>

        {/* DOTS */}
        <div className="absolute -bottom-4 inset-x-0 h-10 bg-gradient-to-t from-neutral-900"></div>
        {renderDots()}

        {/* NAV */}
        <div className="absolute opacity-0 group-hover:opacity-100 transition-opacity flex top-1/2 transform -translate-y-1/2 inset-x-2 justify-between">
          <NextPrev className="w-full justify-between" btnClassName="w-8 h-8" />
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-GallerySlider ${className}`} data-nc-id="GallerySlider">
      {renderSliderGallery()}
    </div>
  );
};

export default GallerySlider;
