import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ImageSection from "components/ImageComponent/imageComponent";
import Textarea from "shared/Textarea/Textarea";
import Label from "components/Label/Label";
import { Tooltip } from 'react-tooltip'
import "react-tooltip/dist/react-tooltip.css";
import axios from 'axios';
import moment from "moment";
import RentReceipt from "./RentReciept";
import { loadStripe } from "@stripe/stripe-js";
import { Stripe } from "@stripe/stripe-js";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import airbnblogo from './Ch/airbnb.png';
import EuropeaLogo from './Ch/europea.png';
import ExpediaLogo from './Ch/expedia.png';
import BookingLogo from './Ch/booking.png';
import VrboLogo from './Ch/vrbo.png';
import googleLogo from './Ch/google.png';
import homeLike from './Ch/homelike.png';
import Homesandvillas from './Ch/homesandvillas.png';
import HousingAnywhere from './Ch/housinganywhere.png';
import TravelAgency from './Ch/travelagency.png';
import relocation from './Ch/relocation.jpg';
import logo from './Ch/newlogoeuropea.png'
import { base_url } from '../../constant/constant';
// @ts-ignore
import html2pdf from 'html2pdf.js';
import GalleryForBookingSlider from "components/GallerySlider/GallerySliderForBookingPage";


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export interface MyBookingsProps {
  className?: string;
  property_id?: {};
}

interface Property {
  title: string;
  licence: String;
}
interface BookingData {
  property_id: Property | null;
  guest_name: string;
  company_invoice: string;
  number_of_person: string;
  rent: string;
  guest_phone: string;
  status: string;
  comment: string;
  channel_name: string;
  city_tax: any;
  city_tax_status: any;
  cleaning_fees: any;
  cleaning_fees_status: any;
  service_fees: any;
  service_fees_status: any;
  extra_fees: any;
  extra_fees_status: any;
  rent_status: any;
  gross_amount: any;

}

interface PropertyDetails {
  _id: any
  title: string;
  reference_no: string;
  address: string;
  check_in_time: any;
  check_out_time: any;
  bedrooms: any;
  bathrooms: any;
  adults: any
  total_size: any;
  description: {
    en: any;
    fr: any;
  }
}

interface AddBookingCommentResponse {
  title: string;
  error: boolean;
  message?: string; // For unexpected server errors
}

interface AddBookingCommentRequest {
  id: string;
  comment: string;
}

const MyBookings: FC<MyBookingsProps> = ({ className = "" }) => {

  const [activeImage, setActiveImage] = useState<number | null>(1);
  const [selectedId, setSelectedId] = useState<string | ''>();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
  const [activeTab, setActiveTab] = useState('TOURS');
  const [bookingData, setBookingData] = useState<BookingData | null>(null);
  const [images, setImages] = useState([])
  const [checkin, setCheckIn] = useState<string | ''>()
  const [checkOut, setCheckOut] = useState<string | ''>()
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  // const stripe = useStripe();
  // const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState("");
  const [amount, setAmount] = useState(1000);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [comment, setComment] = useState<string>("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [destinationContent, setDestinationcontent] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [propertId, setPropertyId] = useState<PropertyDetails>()
  const contentRef = useRef<HTMLDivElement>(null);

  const openDialog = (): void => {
    setIsDialogOpen(true);
  };

  const closeDialog = (): void => {
    setIsDialogOpen(false);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    console.log("Email submitted:", email);
    // Add your email handling logic here (e.g., send email or store it)
    setEmail("");
    closeDialog();
  };

  const getBookingDetails = async () => {
    try {
      const url = new URL(window.location.href);
      const id = url.searchParams.get("id");
      const response = await axios.get(`${base_url}/booking/booking-detatils?id=${id}`); // Replace with your API endpoint
      console.log("kdjdklakdjk", response.data)
      const bookData = response.data.data
      setBookingData(bookData);
      setImages(bookData.images)
      const startdate = moment(bookData.start_date);
      const enddate = moment(bookData.end_date);
      const formattedDate = startdate.format("MMMM Do")
      const formattedEndDate = enddate.format("MMMM Do")
      setCheckIn(formattedDate)
      setCheckOut(formattedEndDate)
      setComment(bookData?.comment)
      setPropertyId(bookData.property_id);
    } catch (err) {
      console.log("dddddddddddd", err)
    } finally {

    }
  };
  useEffect(() => {
    getBookingDetails()
  }, [])

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
  };


  const [isAnimating, setIsAnimating] = useState<boolean>(false);

  useEffect(() => {
    setIsAnimating(true);
    const timer = setTimeout(() => setIsAnimating(false), 300);
    return () => clearTimeout(timer);
  }, [currentImageIndex]);


  const generatePDF = async () => {
    if (!contentRef.current) return;

    const targetDate = new Date();
    const formattedDate = `${String(targetDate.getFullYear()).slice(-2)}${String(
      targetDate.getMonth() + 1
    ).padStart(2, "0")}${String(targetDate.getDate()).padStart(2, "0")}`;
    const title = `Invoice of ${propertId?.title} `;

    const options = {
      margin: 10,
      filename: title,
      image: { type: "jpeg", quality: 1.0 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    await html2pdf(contentRef.current, options);
  };
    // const stripePublicKey = "pk_test_51QRSaBDgf9hHTePcbPg5OZ0Updg5nJbJ5e9tdtj9vphrVeCxXQ8bVmT9nM0Y74XuMTlqPWVy3to8TnNVALZdG3N700QdyeERC0"; //developer
  // const stripePublicKey = "pk_test_51QNtMrDypEZS83khQOJlDeUgijVZNDvSyKalrmiFVNlLf8w5QXq0zkwZ5ESDUghDZ7QTzAtUUUt547BnBiIxpWEJ0047Sabpco" // test live
    const stripePublicKey = "pk_live_51QNtMrDypEZS83khOQXPeoNLaZXE955nCTrSpYC6xeqnOJH3bNV1WCiNaoGCC0P7xMMnaFrYmpBTZQ7azL4CF5mH00YTg4tPTA" //  live

  const handlePayment = async (amount: number): Promise<void> => {
    try {
      const amount = Number(bookingData?.rent)
      if (amount <= 0) {
        setSnackbarSeverity('error');
        setSnackbarMessage('Amount should be grater than zero');
        return setSnackbarOpen(true);
      }
      const url = new URL(window.location.href);
      const id = url.searchParams.get("id")
      if (id) {

        setIsProcessing(true);
        const stripe = await loadStripe(stripePublicKey);
        if (!stripe) {
          throw new Error("Stripe failed to load.");
        }

        const { data } = await axios.post(`${base_url}/stripe/create-checkout-session`, {
          amount,
          currency: "eur",
          bookingId: id
        });

        if (!data.sessionId) {
          throw new Error("Failed to create a checkout session.");
        }

        const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });

        if (result.error) {
          throw new Error(`Stripe Checkout redirection failed: ${result.error.message}`);
        }

      } else {
        setSnackbarSeverity('error');
        setSnackbarMessage('Error: Booking id is missing');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setMessage(`Error: ${(error as Error).message}`);
    } finally {
      setIsProcessing(false);
    }
  };

  const fetchDestination = async (location: any) => {
    let city = location && location.location ? location.location : "Paris"
    const response = await axios.get(`${base_url}/propertyDestination/list_destination?city=${city}`)

    if (response.data.error === false) {
      console.log("sksksksksk", response.data.data)
      let data = response.data.data
      setDestinationcontent(data[0]?.content)
      setSnackbarSeverity('success');
      setSnackbarMessage(response.data.title);
      setSnackbarOpen(true);
    }
    else {
      setSnackbarSeverity('error');
      setSnackbarMessage('Something went wrong');
      setSnackbarOpen(true);

    }
  };

  useEffect(() => {
    if (bookingData?.property_id) {
      fetchDestination(bookingData?.property_id)
    }

  }, [bookingData?.property_id])

  const handlePreviousImage = useCallback(() => {
    setCurrentImageIndex((prev) => (prev > 0 ? prev - 1 : images.length - 1));
  }, [images]);

  const handleNextImage = useCallback(() => {
    setCurrentImageIndex((prev) => (prev < images.length - 1 ? prev + 1 : 0));
  }, [images]);


  const handleSubmit = async (): Promise<AddBookingCommentResponse> => {
    try {
      const url = new URL(window.location.href);
      const id = url.searchParams.get("id");
      if (!id || !comment) {
        setSnackbarSeverity('error');
        setSnackbarMessage('Booking ID or comment is missing.');
        setSnackbarOpen(true);
        return { title: 'Booking ID or comment is missing.', error: true };
      }

      const data = { comment, id };

      const response = await axios.post<AddBookingCommentResponse>(
        `${base_url}/booking/add_booking_comment`,
        data
      );

      if (response?.data.error) {
        setSnackbarSeverity('error');
        setSnackbarMessage(response?.data.title || 'An error occurred.');
      } else {
        setSnackbarSeverity('success');
        setSnackbarMessage(response?.data.title || 'Comment added successfully.');
      }

      setSnackbarOpen(true);
      return response.data;
    } catch (error: any) {
      setSnackbarSeverity('error');
      setSnackbarMessage('Something went wrong');
      setSnackbarOpen(true);

      if (error.response) {
        return error.response.data;
      }

      throw new Error('Something went wrong');
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const getLogo = (channel_name?: string): JSX.Element | null => {
    const logoMap: Record<string, string> = {
      Airbnb: airbnblogo,
      Expedia: ExpediaLogo,
      Europea: EuropeaLogo,
      Booking: BookingLogo,
      Vrbo: VrboLogo,
      Google: googleLogo,
      Homelike: homeLike,
      Homesandvillas: Homesandvillas,
      HousingAnywhere: HousingAnywhere,
      TravelAgency: TravelAgency,
      Relocate: relocation,
    };

    if (!channel_name) {
      return null;
    }

    const logoSrc = logoMap[channel_name];
    return logoSrc ? (
      <img style={{ height: "98px" }} height={95} src={logoSrc} alt={`${channel_name} Logo`} />
    ) : null;
  };

  const getImageVariantUrl = (baseUrl: string, variantSuffix: string) => {
    return baseUrl?.replace(/(\.[\w\d_-]+)$/i, `${variantSuffix}$1`);
  };

  const handleImageError = (e: any, item: any) => {
    e.target.onerror = null;
    e.target.src = item;
  }

  interface PropertyDescriptionProps {
    propertyDesc?: string;
    propertyId?: string;
  }

  const PropertyDescription: React.FC<PropertyDescriptionProps> = ({ propertyDesc, propertyId }) => {
    const MAX_LENGTH = 450; // Character limit
    const description = propertyDesc || "";
    const isLong = description.length > MAX_LENGTH;
    const truncatedText = isLong ? description.slice(0, MAX_LENGTH) + "." : description;

    return (
      <p className="text-sm text-neutral-700 leading-relaxed">
        {truncatedText}
        {isLong && (
          <a
            style={{ color: "rgb(176, 124, 9)" }}
            href={`/listing-stay-detail?id=${propertyId}`} // Update this with the correct listing URL
            className="text-gold-500 cursor-pointer"
          >
            …
          </a>
        )}
      </p>
    );
  };
  return (
    <div style={{ backgroundColor: "#0c586E" }} className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>My Booking </title>
      </Helmet>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <div className="container py-8 lg:py-10">
        <div >
          <div className="flex justify-center" >
            <img style={{ height: "30px" }} src="\favicon.ico" />
            <p className="text-white pl-1 pt-1">Europea</p>
          </div>
          <div className="flex flex-col gap-8 p-4 md:p-8 bg-white-100">
            <div className="flex flex-col md:flex-row gap-4">
              <ImageSection
                imageUrl="/logo/serviceBtn1.png"
                text="VIEW YOUR BOOKING"
                isActive={activeImage === 1}
                onClick={() => setActiveImage(1)}
              />
              <ImageSection
                imageUrl="/logo/service-btn-2.png"
                text="YOUR DESTINATION"
                isActive={activeImage === 2}
                onClick={() => setActiveImage(2)}
              />
            </div>

            {activeImage === 1 &&
              <>
                <div className="flex flex-col md:flex-row justify-center items-start gap-8">
                  <div className="w-full md:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                      <div className="w-full">
                        <div className="text-[#0c586e] space-y-6">
                          <h3 className="text-xl darkTitleForHeader11 inline-block font-semibold">Booking details</h3>
                          <hr className="-mx-6" />
                          <div className="flex flex-col space-y-4">
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                              <span className="flex-1">Client name:</span>
                              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {bookingData?.guest_name}
                                {/* company name UPCE - VAT number CZ00216275. Zuzana Auská */}
                              </span>
                            </div>
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                              <span className="flex-1">Reservation number:</span>
                              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {bookingData?.company_invoice}
                              </span>
                            </div>
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                              <span className="flex-1">Arrival:</span>
                              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {/* October 21st */}
                                {checkin}
                              </span>
                            </div>
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                              <span className="flex-1">Departure:</span>
                              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {/* November 13th */}
                                {checkOut}
                              </span>
                            </div>
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                              <span className="flex-1">Number of persons:</span>
                              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {/* 2 */}
                                {bookingData?.number_of_person}
                              </span>
                            </div>
                            <div className="flex items-center justify-between align-center text-neutral-6000 dark:text-neutral-300 !-mt-1">
                              <span className="flex-1">Booking channel:</span>
                              <span className="flex-1 ">
                                {getLogo(bookingData?.channel_name)}
                              </span>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                      <div className="space-y-6 ">
                        <h3
                          className="text-xl darkTitleForHeader1 inline-block font-semibold">My Arrival</h3>
                        <hr className="-mx-6" />
                        <div className="flex flex-col space-y-4">

                          <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Check-In</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                              {moment(propertId?.check_in_time, "HH:mm").format("hh:mm A")}
                            </span>
                          </div>
                          <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">Check-Out</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                              {moment(propertId?.check_out_time, "HH:mm").format("hh:mm A")}
                            </span>
                          </div>
                          <div className="space-y-1">
                            <Label>Please provide any arrival details: </Label>
                            <Textarea onChange={(e: any) => { setComment(e.target.value) }} value={comment} placeholder="" />

                          </div>

                          <div className="mt-8">
                            <button onClick={handleSubmit} style={{ fontWeight: 500 }} className="bg-[#BF8c4c] text-white uppercase px-6 py-2 rounded">
                              Submit
                            </button>
                            {/* <ButtonPrimary href="/">Submit</ButtonPrimary> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full bg-white mt-6  p-6 rounded-lg shadow-md">
                    <div className="space-y-6">
                      <h3 className="text-xl darkTitleForHeader1 inline-block font-semibold">Cost Summary</h3>
                      <hr className="-mx-6" />
                      <div className="flex flex-col space-y-4">
                        {/* <div className="flex text-neutral-6000 dark:text-neutral-300">
                     <span className="flex-1">Residence:</span>

                   </div> */}
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Rent:</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            € {bookingData?.rent}
                          </span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {bookingData?.rent_status === "paid" ? (
                              <span style={{ color: "green" }}>Paid</span>
                            ) : (
                              <span style={{ color: "red" }}>Not paid</span>
                            )}
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">City Tax:</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            €  {bookingData?.city_tax}
                          </span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {bookingData?.city_tax_status == "paid" ? (
                              <span style={{ color: "green" }}>Paid</span>
                            ) : (
                              <span style={{ color: "red" }}>Not paid</span>
                            )}
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Service fees:</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            €  {Number(bookingData?.service_fees || 0)}
                          </span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {bookingData?.service_fees_status == "paid" ? (
                              <span style={{ color: "green" }}>Paid</span>
                            ) : (
                              <span style={{ color: "red" }}>Not paid</span>
                            )}
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Cleaning fees:</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            €  {Number(bookingData?.cleaning_fees || 0) + Number(bookingData?.extra_fees || 0) + Number(bookingData?.service_fees || 0)}
                          </span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {bookingData?.cleaning_fees_status == "paid" ? (
                              <span style={{ color: "green" }}>Paid</span>
                            ) : (
                              <span style={{ color: "red" }}>Not paid</span>
                            )}
                          </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Extra fees:</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            € {Number(bookingData?.extra_fees || 0)}
                          </span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {bookingData?.extra_fees_status == "paid" ? (
                              <span style={{ color: "green" }}>Paid</span>
                            ) : (
                              <span style={{ color: "red" }}>Not paid</span>
                            )}
                          </span>
                        </div>
                        <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                          <span className="flex-1">Total*:</span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            €  {((Number(bookingData?.gross_amount || 0) + Number(bookingData?.city_tax || 0))).toFixed(2)}
                          </span>
                          <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                            {bookingData?.status == "paid" ? (
                              <span style={{ color: "green" }}>Paid</span>
                            ) : (
                              <span style={{ color: "red" }}>Not paid</span>
                            )}
                          </span>
                        </div>
                        <div className="flex">
                          <span className="mr-1"> * </span>
                          <p style={{ textAlign: "justify" }}>
                            Total cost does not include booking channel commission except for Vrbo / Abritel reservations.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      {(bookingData?.status == "paid" && bookingData?.rent_status == "paid" && bookingData?.extra_fees_status == "paid" &&
                        bookingData?.city_tax_status == "paid" && bookingData?.cleaning_fees_status == "paid" && bookingData?.service_fees_status == "paid"
                      ) ? <button style={{ fontWeight: 500 }} onClick={generatePDF} className="bg-[#BF8c4c] uppercase text-white px-6 py-2 rounded">
                        Download Invoice
                      </button> :
                        <button style={{ fontWeight: 500 }} onClick={() => { handlePayment(100) }} className="bg-[#BF8c4c] uppercase text-white px-6 py-2 rounded">
                          Pay now
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </>}
            {activeImage === 2 && <>
              <div className="bg-white rounded-lg shadow-md">
                <div className="w-full mx-auto">
                  <h3 className="text-xl darkTitleForHeader1 inline-block p-6 font-semibold">Quick guide to your destination</h3>
                  <hr className="" />

                  <div
                    className="p-6"
                    dangerouslySetInnerHTML={{ __html: destinationContent }}
                    style={{ fontFamily: "Arial, sans-serif" }}
                  />
                </div>
              </div>
            </>
            }
          </div>
        </div>
        {activeImage === 1 && <div className="px-4 lg:px-8">
          <div className="flex flex-wrap bg-white p-6 rounded-lg shadow-md">
            <div className="w-full md:w-1/2">

              <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
                {images?.length > 0 && <GalleryForBookingSlider
                  galleryImgs={images}
                />}
              </div>
            </div>

            <div className="w-full md:w-1/2 p-6 space-y-4">
              <a href={`/listing-stay-detail?id=${propertId?._id}`} >
                <h2 className="text-2xl darkTitleForHeader1 inline-block">
                  {bookingData?.property_id?.title}
                </h2>
              </a>
              <p className="text-sm font-medium text-[#0c586e]">{bookingData?.property_id?.licence} licence</p>

              <div className="flex space-x-4">
                <div data-tooltip-id="my-tooltip"
                  style={{ border: '2px solid #b07c09' }}
                  onMouseEnter={() => { setSelectedId('my-tooltip') }}
                  onMouseLeave={() => { setSelectedId('') }}
                  data-tooltip-content={`${propertId?.bedrooms || 0} bedroom${(propertId?.bedrooms || 0) > 1 ? 's' : ''}`}
                  className="w-8 h-8 rounded-full border border-[solid 2px #b07c09] flex items-center justify-center">
                  <i className="la la-bed"></i>
                </div>
                <div data-tooltip-id="my-tooltip1"
                  style={{ border: '2px solid #b07c09' }}
                  onMouseEnter={() => { setSelectedId('my-tooltip1') }}
                  onMouseLeave={() => { setSelectedId('') }}
                  data-tooltip-content={propertId?.total_size + " m2"} className="w-8 h-8 rounded-full border border-[solid 2px #b07c09] flex items-center justify-center ">
                  <i className="la la-expand-arrows-alt"></i>
                </div>
                <div data-tooltip-id="my-tooltip2"
                  style={{ border: '2px solid #b07c09' }}
                  onMouseEnter={() => { setSelectedId('my-tooltip2') }}
                  onMouseLeave={() => { setSelectedId('') }}
                  data-tooltip-content={`${propertId?.bathrooms || 0} bedroom${(propertId?.bathrooms || 0) > 1 ? 's' : ''}`}
                  className="w-8 h-8 rounded-full border border-[solid 2px #b07c09] flex items-center justify-center">
                  <i className="la la-shower"></i>
                </div>
                <div style={{ border: '2px solid #b07c09' }} data-tooltip-id="my-tooltip3" onMouseEnter={() => { setSelectedId('my-tooltip3') }}
                  onMouseLeave={() => { setSelectedId('') }}
                  data-tooltip-content={bookingData?.number_of_person + " people"} className="w-8 h-8 rounded-full border border-[solid 2px #b07c09] flex items-center justify-center ">
                  <i className="la la-users"></i>
                </div>
                {/* <div style={{ border: '2px solid #b07c09' }} data-tooltip-content="Rating" className="w-8 h-8 rounded-full border border-[solid 2px #b07c09] flex items-center justify-center text-[red]">
                  <i className="la la-star"></i>
                </div> */}
                <Tooltip id={selectedId} place="top" />
              </div>

              <p style={{ textAlign: "justify" }} className="text-sm text-neutral-700 leading-relaxed ">
                {/* {propertId?.description?.en} */}
                <PropertyDescription propertyDesc={propertId?.description?.en} propertyId={propertId?._id} />
              </p>
            </div>
          </div>


        </div>}
      </div>
      {isDialogOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          {/* Dialog Box */}
          <div style={{ width: '400px' }} className="bg-white p-6 rounded-lg shadow-lg w-100 text-center">
            <p className="text-gray-800 mb-4">Share this programme </p>
            <form onSubmit={handleFormSubmit} className="space-y-4">
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter email address"
                className="w-full px-4 py-2 border rounded text-gray-700 focus:outline-none focus:ring-2 focus:ring-[#b36a06]"
                required
              />

              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={closeDialog}
                  className="bg-gray-200 px-4 py-2 rounded font-bold text-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#b36a06] text-white px-4 py-2 rounded font-bold"
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* <div
      dangerouslySetInnerHTML={{ __html: destinationContent }}
      style={{ margin: "20px", fontFamily: "Arial, sans-serif" }}
    /> */}
      <div style={{ display: "none" }}>
        <RentReceipt generatePDF={generatePDF} contentRef={contentRef} property_id={propertId} data={bookingData} logo={logo} month={7} year={2024} />
      </div>
    </div>
  );
};

export default MyBookings;
