import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import axios from "axios";
import { base_url } from "constant/constant";
import moment from 'moment';
import GoogleMapReact from "google-map-react";
import Button from "shared/Button/Button";
import { loadStripe } from "@stripe/stripe-js";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ContactFormModal from "./CustomerDilouge";
import PriceBreakdownPopup from "./CustomRateDisplay";
import CustomFees from "./CustomeFeesDilouge";
import CustomerDetails from "./CustomerDetails";
import { useAppContext } from "AppContext";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";


export interface propertyDetails {
  type?: string,
  house_rules?: string,
  title?: string,
  cancellation_policy?: string,
  description?: descriptionModel,
  location?: string,
  bedrooms?: any,
  bathrooms?: any,
  toilets?: any,
  amenities?: any,
  policies?: any,
  security_and_safety?: any,
  accessibility?: any,
  [key: string]: any,
  coordinates?: cordinatesDetails
  check_in_time?: any,
  check_out_time?: any,
  residence_licence_number?: any
}

export interface descriptionModel {
  en?: string;
  fr?: string
}
export interface cordinatesDetails {
  coordinates: any
}
export interface StayDatesRangeInputProps {
  className?: string;
}

interface RateData {
  totalRate: number;
  nights: number;
  cleaningFees: number;
  communityFees: number;
  managementFees: number;
  cityTax: number;
  calRateWithoutfees: number;
  weeklyDisc: number,
  monthlyDisc: number,
  priceListByDates: any,
  rentWithDescount: number
}

interface ApiResponse {
  error: boolean;
  title: string;
  data: RateData;
}

type FormValues = {
  name: string;
  email: string;
  phone: string;
  isChecked: boolean;
};


const StayDetailPageContainer: FC<{}> = () => {
  const { setTotalAmount, updateFilterState, paymentSectionRef } = useAppContext();

  let [images, setImages] = useState([])
  const [blockedDates, setBlockedDates] = useState<Date[]>([]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [numberOfPerson, setNumberOfPerson] = useState(1);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(0);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);
  const [openContactModal, setOpenContactModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);


  let [propertyDetails, setPropertyDetails] = useState<propertyDetails>();
  const [rateData, setRateData] = useState<RateData | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
    phone: "",
    isChecked: false,
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE&id=${id}`);
  };

  const fetchPropertyInfo = async () => {
    const url = new URL(window.location.href);
    const id = url.searchParams.get("id");
    const response = await axios.get(`${base_url}/property/website_property_details?property_id=${id}`)

    if (response.data.error === false) {
      let data = response.data.data
      setImages(data?.images)
      setPropertyDetails(data)
    }
    else {

    }
  }

  const CalculateRate = async (startDate: any, endDate: any, number_of_guests: any) => {
    try {
      setLoading1(true)
      const url = new URL(window.location.href);
      const id = url.searchParams.get("id");
      const totalGuests = guestChildrenInputValue + number_of_guests;
      const response = await axios.post<ApiResponse>(`${base_url}/property/calculate_rate`, {
        propertyId: id,
        checkInDate: moment(startDate).format("YYYY-MM-DD"),
        checkOutDate: moment(endDate).format("YYYY-MM-DD"),
        number_of_guest: Number(totalGuests),
      });
      setLoading1(false)
      if (!response.data.error) {
        const rateData = response.data.data
        const perDayRate = rateData.calRateWithoutfees / rateData.nights
        setTotalAmount(perDayRate)
        setRateData(response.data.data);
        setErrorMessage(null);
        setLoading(true)

      } else {
        setErrorMessage(response.data.title);
      }
    } catch (error) {
      setLoading1(false)
      console.error("Error fetching rate data:", error);
      setErrorMessage("Something went wrong while calculating the rate.");
    }
  };

  const addCustomerInfo = () => {
    if (formValues.name && formValues.email && formValues.isChecked) {
      bookProperty()
    } else {
      setOpenContactModal(true)
    }
  }

  const bookProperty = async () => {
    try {
      const totalRate = rateData?.totalRate;
      const cleaningFees = Number(rateData?.cleaningFees).toFixed(2)
      const community_fees = rateData?.communityFees;
      const management_fees = rateData?.managementFees;
      const cityTax = Number(rateData?.cityTax).toFixed(2);
      const rent_excluding_fees = Number(rateData?.rentWithDescount).toFixed(2)
      const grossAmount = (Number(totalRate) - Number(cityTax)).toFixed(2)
      const bookingRes = await axios.post(`${base_url}/booking/booking_created_from_website`, {
        property_id: id,
        type: "Booked by europea",
        status: "pending",
        start_date: moment(startDate).format("YYYY-MM-DD"),
        end_date: moment(endDate).format("YYYY-MM-DD"),
        cleaning_fees: cleaningFees, community_fees, rent: rent_excluding_fees, city_tax: cityTax,
        gross_amount: grossAmount, management_fees, number_of_person: Number(numberOfPerson + guestChildrenInputValue),
        number_of_adults: numberOfPerson,
        number_of_children: guestChildrenInputValue,
        number_of_infants: guestInfantsInputValue,
        guest_name: formValues.name,
        guest_email: formValues.email,
        guest_phone: formValues.phone
      });
      if (!bookingRes.data.error) {
        setErrorMessage(null);
        const id = bookingRes.data.data._id
        const amt = bookingRes.data.data.rent
        await handlePayment(amt, id)
      }
      else {
        setErrorMessage(bookingRes.data.title || "Somethig went wrong");
      }
    } catch (err) {
      setErrorMessage("Something went wrong while calculating the rate.");
    }
  }


  const handlePayment = async (amt: number, id: any): Promise<void> => {
    // const stripePublicKey = "pk_test_51QRSaBDgf9hHTePcbPg5OZ0Updg5nJbJ5e9tdtj9vphrVeCxXQ8bVmT9nM0Y74XuMTlqPWVy3to8TnNVALZdG3N700QdyeERC0"; //developer
    // const stripePublicKey = "pk_test_51QNtMrDypEZS83khQOJlDeUgijVZNDvSyKalrmiFVNlLf8w5QXq0zkwZ5ESDUghDZ7QTzAtUUUt547BnBiIxpWEJ0047Sabpco" // test live
    const stripePublicKey = "pk_live_51QNtMrDypEZS83khOQXPeoNLaZXE955nCTrSpYC6xeqnOJH3bNV1WCiNaoGCC0P7xMMnaFrYmpBTZQ7azL4CF5mH00YTg4tPTA" //  live

    try {
      const amount = Number(amt)
      if (amount <= 0) {
        // setSnackbarSeverity('error');
        // setSnackbarMessage('Amount should be grater than zero');
        // return setSnackbarOpen(true);
      }
      // const url = new URL(window.location.href);
      // const id = url.searchParams.get("id")
      if (id) {
        const stripe = await loadStripe(stripePublicKey);
        if (!stripe) {
          throw new Error("Stripe failed to load.");
        }

        const { data } = await axios.post(`${base_url}/stripe/create-checkout-session`, {
          amount,
          currency: "eur",
          bookingId: id
        });

        if (!data.sessionId) {
          throw new Error("Failed to create a checkout session.");
        }

        const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });

        if (result.error) {
          throw new Error(`Stripe Checkout redirection failed: ${result.error.message}`);
        }

      } else {
        // setSnackbarSeverity('error');
        // setSnackbarMessage('Error: Booking id is missing');
        // setSnackbarOpen(true);
      }
    } catch (error) {
      // setMessage(`Error: ${(error as Error).message}`);
    } finally {
    }
  };

  useEffect(() => {
    fetchPropertyInfo()
  }, [])

  useEffect(() => {
    const start_date = url.searchParams.get("start_date");
    const end_date = url.searchParams.get("end_date");
    const numberOfPerson = url.searchParams.get("number_of_guests");
    const numberOfChild = url.searchParams.get("number_of_children");
    const numberOfInfants = url.searchParams.get("number_of_infants");
    if (start_date && start_date != "null") {
      setStartDate(start_date ? new Date(start_date) : null)
    }
    if (end_date && end_date != "null") {
      setEndDate(new Date(end_date))
    }
    if (numberOfPerson) {
      setNumberOfPerson(Number(numberOfPerson))
    }
    if (numberOfChild) {
      setGuestChildrenInputValue(Number(numberOfChild))
    }
    if (numberOfInfants) {
      setGuestInfantsInputValue(Number(numberOfInfants))
    }
    if (end_date && start_date && start_date != "null" && end_date != "null") {
      CalculateRate(start_date, end_date, numberOfPerson)
    }

  }, [])

  useEffect(() => {
    updateFilterState("checkOutDate", endDate)
  }, [endDate])

  useEffect(() => {
    updateFilterState("checkInDate", startDate)
  }, [startDate])

  const generateDatesInRange = async (start: Date, end: Date) => {
    console.log("Start Date:", start);
    console.log("End Date:", end);

    // Ensure both start and end are valid Date objects
    if (!(start instanceof Date) || isNaN(start.getTime())) {
      console.error("Invalid start date");
      return [];
    }

    if (!(end instanceof Date) || isNaN(end.getTime())) {
      console.error("Invalid end date");
      return [];
    }

    const dates: Date[] = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      await dates.push(new Date(currentDate));
      await currentDate.setDate(currentDate.getDate() + 1);
    }

    console.log("Generated Dates:", dates);
    return dates;
  };

  const getBooking = async () => {
    const token = localStorage.getItem('europea_token');
    const response = await axios.get(`${base_url}/booking/booking_listing_for_website?property_id=${id}`);

    // Map through the events and await each generateDatesInRange call using Promise.all
    const events = await Promise.all(
      response?.data?.data?.map(async (event: any) => {
        return await generateDatesInRange(new Date(event.start_date), new Date(event.end_date));
      })
    );

    console.log("Blocked Dates:", events);

    // Flatten the array if generateDatesInRange returns an array for each event
    const allBlockedDates = events.flat();
    setBlockedDates(allBlockedDates);
  };

  useEffect(() => {
    getBooking();
  }, []);

  // useEffect(() => {
  //   const dates = bookedDateRanges.flatMap((range) =>
  //     generateDatesInRange(range.start, range.end)
  //   );
  //   setBlockedDates(dates);
  // }, []);


  const excludeStreetNumber = (address: String) => {
    return address?.replace(/^\d+\s*/, ""); // Removes leading numbers and whitespace
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap shadow-md bg-white">
        <h2 className="text-2xl darkTitleForHeader inline-block pl-4 md:pl-0">Residence Information</h2>
        <hr className="-mx-1 sm:-mx-6" />
        <div className="text-neutral-6000 dark:text-neutral-300 pl-4 md:pl-0">
          <div className="flex justify-between items-center" style={{ gap: '16px' }}>
            <div className="flex items-center justify-between gap-4 flex-wrap w-full">
              <span
                className="px-3 py-2 text-white bg-[#DBAE74] rounded-full flex-shrink-0"
                style={{
                  maxWidth: "300px",
                  minWidth: "75px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {propertyDetails?.residence_type}
              </span>

              <div className="flex justify-center items-center flex-1 min-w-[150px] max-w-[calc(100%-200px)] mx-2">
                <i className="las la-map-marker-alt la-lg text-xl flex-shrink-0"></i>
                <div className="ml-1 text-sm break-words">
                  {excludeStreetNumber(propertyDetails?.address)}
                </div>
              </div>

              {propertyDetails?.residence_licence_number && (
                <span className="text-[#0c586e] text-sm font-semibold flex-shrink-0 ml-2">
                  {propertyDetails?.residence_licence_number}
                </span>
              )}
            </div>
          </div>
          <br />
          <h2 className="text-2xl mt-5 text-black">
            {propertyDetails?.title}
          </h2>
          <p>
            {propertyDetails?.bedrooms ?? 1} {propertyDetails?.bedrooms > 1 ? ' Bedrooms' : ' Bedroom'} .   {propertyDetails?.bathrooms ?? 1} {propertyDetails?.bathrooms > 1 ? ' Bathrooms' : ' Bathroom'} .   {propertyDetails?.toilets ?? 1}  {propertyDetails?.toilets > 1 ? ' Toilets' : ' Toilet'}
          </p>
          <br />
          <div className="text-gray-700 text-sm leading-relaxed text-justify" style={{ whiteSpace: "pre-line" }}>
            {propertyDetails?.description?.en}
          </div>
          <br />
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    const amenitiesData = propertyDetails?.amenities || [];

    return (
      <div className="listingSection__wrap shadow-md bg-white">
        <h2 className="text-2xl darkTitleForHeader inline-block pl-4 md:pl-4">Amenities</h2>
        <hr className="-mx-1 sm:-mx-6" />
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 p-4 pl-4 md:pl-2">
          {Amenities_demos.map((item) => {
            const isPresent = amenitiesData.some(
              (amenity: any) =>
                amenity.name.toLowerCase().includes(item.name.toLowerCase())
            );
            const amenityValue = amenitiesData.find(
              (amenity: any) => amenity.name.toLowerCase().includes(item.name.toLowerCase())
            )?.value;

            const isValuePresent = Array.isArray(amenityValue) && amenityValue.length > 0;

            const showTooltip = isValuePresent && amenityValue.length > 1;

            return (
              <div key={item.name} className="flex items-center space-x-3">
                <i className={`text-3xl las ${item.icon} ${isPresent ? 'text-neutral-700' : 'text-neutral-400 '}`}></i>
                <span
                  className={`font-semibold ${isPresent ? 'text-neutral-700' : 'text-neutral-400 line-through'} ${showTooltip ? 'cursor-pointer' : ''}`}
                  title={showTooltip ? amenityValue.join(", ") : ""}
                >
                  {item.name}
                  {isValuePresent ? showTooltip ? ` (${amenityValue[0]}..)` : ` (${amenityValue[0]})` : ""}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };


  const [activeTab, setActiveTab] = useState<string>('Security & Safety');

  const renderSection4 = () => {

    const tabs: string[] = ['Security & Safety', 'Policies', 'Accessibility'];

    const features: { [key: string]: string[] } = {
      'Security & Safety': [
        'Smoke detectors',
        'Carbon monoxide detector',
        'First aid kit',
        'Medical emergency contact',
        'Police emergency contact',
        'Emergency exit route',
        'Window guards',
        'Fire extinguisher',
        'Laptop safe box',
        'Safe deposit'
      ],
      Policies: [
        'No smoking rooms facilities',
        'Smoking allowed',
        'Designated smoking area',
        'Pets allowed',
        'No pets allowed',
        'Pets accepted under request',
        'Parties allowed',
        'No parties allowed'
      ],
      Accessibility: [
        'Wheelchair access',
        'Handicapped rooms facilities',
        'Elevator',
        'Braille Elevator',
        'Accessible path of travel'
      ],
    };

    const backendDataMapping: { [key: string]: string } = {
      'Security & Safety': 'security_and_safety',
      Policies: 'policies',
      Accessibility: 'accessibility',
    };

    return (
      <div className="listingSection__wrap_rm_p shadow-md">
        <div className="flex border-b" style={{ background: "#ECECEC", height: "70px" }}>
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`w-full py-3 px-4 md:pl-1 text-center ${activeTab === tab
                ? 'text-[#0c586E] !bg-white text-xl leading-[1.35rem]'
                : 'text-gray-500 hover:text-black hover:bg-white-400 text-xl darkTitleForHeader'
                }`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'normal',
                wordBreak: 'break-word',
                width: '100%',
              }}
            >
              {tab}
            </button>
          ))}
        </div>


        <div className="grid grid-cols-1 xl:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 p-8 pl-4 md:pl-9">
          {features[activeTab].map((feature: string) => {
            const backendKey = backendDataMapping[activeTab];
            const backendData: any = propertyDetails?.[backendKey];

            const isPresent =
              backendData &&
              backendData.some(
                (item: any) => item?.toLowerCase() === feature.toLowerCase()
              );

            return (
              <div key={feature} style={isPresent ? {} : { display: 'none' }} className="flex items-center space-x-3">
                <i
                  className="las la-arrow-circle-right la-2x"
                  style={isPresent ? { color: "#0c586E" } : { color: "#dadada", display: 'none' }}
                ></i>
                <span
                  className={`font-semibold ${isPresent ? "text-neutral-700" : "text-neutral-400 line-through hidden"
                    }`}
                >
                  {feature}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const [activTab, setActivTab] = useState<string>('Cancellation Policy');

  const renderSection5 = () => {

    const tabs: string[] = ['Cancellation Policy', 'House Rules'];

    return (
      <div className="listingSection__wrap_rm_p shadow-md bg-white">
        <div className="flex border-b" style={{ background: "#ECECEC", height: "70px" }}>
          {tabs.map((tab) => (
            <button
              key={tab}
              onClick={() => setActivTab(tab)}
              className={`w-full py-3 px-4 text-center ${activTab === tab
                ? 'text-[#0c586E] !bg-white text-xl !leading-[1.35rem]'
                : 'text-gray-500 hover:text-black hover:bg-white-400 text-xl darkTitleForHeader'
                } `}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="grid grid-cols-1 xl:grid-cols-1 gap-6 text-sm text-neutral-700 dark:text-neutral-300 p-8 pl-4 md:pl-9 bg-white">
          {activTab == "House Rules" &&
            propertyDetails?.house_rules?.split('\n').map((rule, index) => (
              rule && <div key={index} className="flex items-center space-x-3">
                <i className="las la-arrow-circle-right la-2x" style={{ color: '#0c586E' }}></i>
                <span className="font-semibold text-neutral-700">
                  {rule.replace(/^-/, '').trim()}
                </span>
              </div>
            ))
          }
          {activTab == "Cancellation Policy" &&
            propertyDetails?.cancellation_policy?.split('\n').map((policy, index) => (
              policy && <div key={index} className="flex items-center space-x-3">
                <i className="las la-arrow-circle-right la-2x" style={{ color: '#0c586E' }}></i>
                <span className="font-semibold text-neutral-700">
                  {policy.replace(/^-/, '').trim()}
                </span>
              </div>
            ))
          }
        </div>


      </div>
    );
  };


  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl shadow-md bg-white">
        <h2 className="text-2xl darkTitleForHeader inline-block">Availability and Rates</h2>
        <hr className="-mx-6" />
        <div className="grid grid-cols-2">
          <div className="flex items-center gap-3 border-gray-300">
            <div className="w-10 h-10 bg-[linear-gradient(270deg,_#0B5B6F_0%,_rgba(141,_168,_215,_1)_200%)] text-white rounded-full flex justify-center items-center">
              <i className="la la-calendar la-lg"></i>
            </div>
            <div>
              <p className="text-base font-medium">Checkin</p>
              <p className="text-sm text-gray-500">{moment(propertyDetails?.check_in_time, "HH:mm").format("hh:mm A")}</p>
            </div>
          </div>

          <div className="flex items-center gap-3 -ml-4">
            <div className="w-10 h-10 bg-[linear-gradient(270deg,_#0B5B6F_0%,_rgba(141,_168,_215,_1)_200%)] text-white rounded-full flex justify-center items-center">
              <i className="la la-calendar-check la-lg"></i>
            </div>
            <div>
              <p className="text-base font-medium">Checkout</p>
              <p className="text-sm text-gray-500">{moment(propertyDetails?.check_out_time, "HH:mm").format("hh:mm A")}</p>
            </div>
          </div>
        </div>
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput setLoading={setLoading} startDateStr={startDate} setStartDateStr={setStartDate} endDateStr={endDate} setEndDateStr={setEndDate} blockedDates={blockedDates} setBlockedDates={setBlockedDates} className="flex-1 z-[11]" />
        </form>
        <div className="flex justify-center mx-4">
          <NcInputNumber
            className="w-full"
            className1="w-[60%]"
            defaultValue={numberOfPerson}
            onChange={(value) => setNumberOfPerson(value)}
            max={20}
            min={1}
            label="Number of Guest"
          />
        </div>
        {!loading && <Button
          disabled={!(startDate && endDate)}
          className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50`}
          onClick={() => CalculateRate(startDate, endDate, numberOfPerson)}
        >
          Calculate
        </Button>}

        {errorMessage && (
          <p style={{ color: "red", marginTop: "10px" }}>
            {errorMessage}
          </p>
        )}

        {loading && rateData ? (
          // <>
          //   <p style={{ marginTop: "20px", color: "#555" }}>
          //     No rate data available. Click the button to calculate.
          //   </p>
          // </>
          <div style={{ marginTop: "20px", border: "1px solid #ddd", borderRadius: "10px", padding: "20px", backgroundColor: "#f9f9f9" }}>
            <h2 style={{ color: "#333", borderBottom: "1px solid #ddd", paddingBottom: "10px" }}>Rate Details</h2>
            <ul style={{ listStyle: "none", padding: "0", lineHeight: "1.8" }}>
              <li style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}><strong>Nights:</strong> + ({rateData.nights} x {Number(rateData.totalRate / rateData.nights).toFixed(2)})</li>
              <li style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}><strong>Cleaning Fees:</strong> + {rateData.cleaningFees}</li>
              <li style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}><strong>Community Fees:</strong> + {rateData.communityFees}</li>
              <li style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}><strong>Management Fees:</strong> + {rateData.managementFees}</li>
              <li style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}><strong>City Tax:</strong> + {rateData.cityTax}</li>
              <li style={{ display: "flex", justifyContent: "space-between", padding: "5px 0" }}><strong>Total Rate:</strong> {rateData.totalRate}</li>
            </ul>
          </div>
        ) : (
          <p style={{ marginTop: "20px", color: "#555", textAlign: "center" }}>
            Click to determine the full cost.
          </p>
        )}
        {loading && <Button
          className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50`}
          onClick={() => bookProperty()}
        >
          Book now
        </Button>}
      </div>
    );
  };
  const renderSidebar1 = () => {
    return (
      <div ref={paymentSectionRef} className="listingSectionSidebar__wrap shadow-xl">
        {/* <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            $19
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /person
            </span>
          </span>
          <StartRating />
        </div> */}

        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput setLoading={setLoading} startDateStr={startDate} setStartDateStr={setStartDate} endDateStr={endDate} setEndDateStr={setEndDate} blockedDates={blockedDates} setBlockedDates={setBlockedDates} className="flex-1 z-[11]" />

          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput
            setLoading={setLoading}
            numberOfPerson={numberOfPerson}
            setNumberOfPerson={setNumberOfPerson}
            guestChildrenInputValue={guestChildrenInputValue}
            setGuestChildrenInputValue={setGuestChildrenInputValue}
            guestInfantsInputValue={guestInfantsInputValue}
            setGuestInfantsInputValue={setGuestInfantsInputValue}
            className="flex-1" />
        </form>
        {(!loading && !loading1) && <Button
          disabled={!(startDate && endDate)}
          className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50`}
          onClick={() => CalculateRate(startDate, endDate, numberOfPerson)}
        >
          Check Availability
        </Button>}
        {loading1 && <Box display="flex" justifyContent="center" alignItems="center" >
          <CircularProgress />
        </Box>}
        {errorMessage && (
          <p style={{ color: "red", marginTop: "10px" }}>
            {errorMessage}
          </p>
        )}
        {loading && rateData ?
          <div className="flex flex-col space-y-4">
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <PriceBreakdownPopup nights={rateData.nights} calRateWithoutfees={rateData.calRateWithoutfees} priceListByDates={rateData.priceListByDates} />
              <span>€ {(rateData.nights * Number(rateData.calRateWithoutfees / rateData.nights)).toFixed(2)}</span>
            </div>
            {rateData.weeklyDisc && <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Long stay discount</span>
              <span style={{ color: "#0c586e" }}>- € {rateData.weeklyDisc}</span>
            </div>}
            {rateData.monthlyDisc && <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Long stay discount</span>
              <span style={{ color: "#0c586e" }}>- € {rateData.monthlyDisc}</span>
            </div>}

            {(rateData.cleaningFees || rateData.communityFees || rateData.managementFees) &&
              // <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              //   <PriceBreakdownPopup nights={rateData.nights} calRateWithoutfees={rateData.calRateWithoutfees} priceListByDates={rateData.priceListByDates} />
              //   <span>€ {(rateData.nights * Number(rateData.calRateWithoutfees / rateData.nights)).toFixed(2)}</span>
              // </div>
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                <CustomFees cleaningFees={rateData.cleaningFees || 0} managementFees={rateData.managementFees || 0} communityFees={rateData.communityFees || 0} />
                <span >+ € {Number(rateData.cleaningFees || 0) + Number(rateData.communityFees || 0) + Number(rateData.managementFees || 0)}</span>
              </div>
            }

            {rateData.cityTax ? <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span>Taxes</span>
              <span >+ € {rateData.cityTax}</span>
            </div> : ''}
            {rateData ? <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <div >
                <span onClick={() => setOpenContactModal(true)} className="block font-semibold underline cursor-pointer">
                  Personal Details
                </span>
              </div>

              <i onClick={() => setOpenContactModal(true)} className="las la-edit text-2xl cursor-pointer"></i>
            </div> : ''}
            <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="flex justify-between font-semibold">
              <span>Total</span>
              <span>€ {Number(rateData.totalRate).toFixed(2)}</span>
            </div>
          </div> :
          <p style={{ marginTop: "20px", color: "#555", textAlign: "center" }}>
            Click to determine the full cost.
          </p>}
        {loading && rateData?.totalRate && <>
          <Button
            className={`ttnc-ButtonPrimary disabled:bg-opacity-70 bg-primary-6000 hover:bg-primary-700 text-neutral-50`}
            onClick={() => addCustomerInfo()}
          >
            Book now
          </Button>
        </>}
      </div>
    );
  };
  const renderSidebarMap = () => {
    const properties = [
      { id: 1, lat: 19.114111373215245, lng: 73.03439074306638, title: "Property 1" },
      { id: 2, lat: 19.115, lng: 73.035, title: "Property 2" },
      { id: 3, lat: 19.116, lng: 73.036, title: "Property 3" },
    ];
    interface MarkerProps {
      lat: number;
      lng: number;
      text: string;
    }

    const Marker: React.FC<MarkerProps> = ({ text }) => (
      <div style={{ transform: 'translate(-50%, -100%)' }}>
        <img
          src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi2_hdpi.png"
          alt="Marker"
          style={{ width: '22px', height: '35px' }}
        />
        {/* {text && <div style={{ color: 'red', fontWeight: 'bold' }}>{text}</div>} */}
      </div>
    );
    const defaultProps = {
      center: {
        lat: propertyDetails?.coordinates?.coordinates[0] || 19.114111373215245,
        lng: propertyDetails?.coordinates?.coordinates[1] || 73.03439074306638,
      },
      zoom: 11
    };
    // const centerLocation = {
    //   lat: 19.114111373215245,
    //   lng: 73.03439074306638,
    // };
    const mapStyles = [
      {
        "elementType": "geometry",
        "stylers": [{ "color": "#F6F6F4" }]
      },
      {
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#523735" }]
      },
      {
        "elementType": "labels.text.stroke",
        "stylers": [{ "color": "#f5f1e6" }]
      },
      {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [{ "color": "#D0ECEF" }]
      },
      {
        "featureType": "landscape.natural",
        "elementType": "geometry",
        "stylers": [{ "color": "#F6F6F4" }]
      },
      {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{ "color": "#E2F2DA" }]
      },
      {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{ "color": "#ffffff" }]
      },
      {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#444444" }]
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#e0e0e0",
          },
        ],
      },
      { "elementType": "labels.text.fill", "stylers": [{ "color": "#4a4a4a", "fontWeight": "bold" }] },
    ]
    return (
      <div className="listingSectionSidebar__wrap shadow-xl shadow-md bg-white">
        <h2 className="text-2xl darkTitleForHeader inline-block">Location</h2>
        <hr className="-mx-6" />
        <div className="grid grid-cols-1 h-[300px]">
          <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
            <div className="rounded-xl overflow-hidden z-0">
              <GoogleMapReact
                options={{
                  styles: mapStyles,
                  gestureHandling: 'cooperative',
                  scrollwheel: false,
                  fullscreenControl: false,
                  mapTypeId: 'roadmap',
                  disableDefaultUI: true
                }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
                // bootstrapURLKeys={{key:"AIzaSyDE4YXJP-Oq9uXDySWbJBOBtwej5bdrw1A"}}
                bootstrapURLKeys={{ key: "AIzaSyDE4YXJP-Oq9uXDySWbJBOBtwej5bdrw1A" }}
              >
                <Marker
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                  text={propertyDetails?.location || ""}
                />
              </GoogleMapReact>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const getImageVariantUrl = (baseUrl: string, variantSuffix: string) => {
    return baseUrl.replace(/(\.[\w\d_-]+)$/i, `${variantSuffix}$1`);
  };

  const handleImageError = (e: any, item: any) => {
    e.target.onerror = null;
    e.target.src = item;
  }
  const navigate = useNavigate();

  return (
    <div style={{ background: "rgb(250,250,250)" }} className="nc-ListingStayDetailPage pt-4 pb-8">
      <header className=" text-[#0c586E]">
        <div className="md:hidden">
          <div className=" shadow-md bg-white !py-2">
            <button className="pl-4" onClick={() => navigate("/")}> Back to Home</button>
          </div>
        </div>
        <div className={`mt-5 relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2 ${images.length == 1 && "h-[50vh] w-[100%]"}`} >
          {images.length == 1 &&
            (<div
              className="col-span-4 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
              onClick={handleOpenModalImageGallery}
            >
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={getImageVariantUrl(images[0], "_compress")}
                onError={(e) => handleImageError(e, images[0])}
                alt=""
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>)
          }
          {images.length > 1 &&
            <>
              <div
                className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                onClick={handleOpenModalImageGallery}
              >
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={getImageVariantUrl(images[0], "_compress")}
                  onError={(e) => handleImageError(e, images[0])}
                  alt=""
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {images?.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""}`}
                >
                  <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                    <img
                      className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                      // src={item || ""}
                      src={getImageVariantUrl(item, "_480")}
                      onError={(e) => handleImageError(e, item)}
                      alt=""
                      sizes="400px"
                    />
                  </div>

                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

              <button
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="w-5 h-5" />
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </button>
            </>
          }
        </div>
      </header>

      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderSection2()}
          {renderSection3()}
          {renderSection4()}
          {renderSection5()}
        </div>
        <ContactFormModal formValues={formValues} bookProperty={bookProperty} setFormValues={setFormValues} isOpen={openContactModal} onClose={() => { setOpenContactModal(false) }} />
        <div className="flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar1()}
            <br />
            {/* {renderSidebarMap()} */}
          </div>
          {/* <div className="sticky top-48"></div> */}
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
