import React, { useRef } from "react";
import Grid from "@mui/material/Grid";
import moment from "moment";
// @ts-ignore
import html2pdf from 'html2pdf.js';
interface PropertyDetails {
  title: string;
  reference_no: string;
  address: string;
}

interface OwnerInfo {
  fiscal_adress?: string;
  vat_n?: string;
}

interface ReservationDetailsProps {
  logo: string;
  data: any;
  // owner_info: OwnerInfo;
  month: number;
  year: number;
  property_id: any;
  contentRef:any;
  generatePDF:any
}

const ReservationDetails: React.FC<ReservationDetailsProps> = ({
  // ReservationcontentRef,
  logo,
  data,
  // owner_info,
  month,
  year,
  property_id,
  contentRef,
  generatePDF
}) => {

  const getLastDateOfMonth = (selectedDate: string): string => {
    const date = new Date(selectedDate);
    date.setMonth(date.getMonth() + 1, 1);
    date.setDate(date.getDate() - 1);
    return `${month}/${date.getDate()}/${year}`;
  };

  // const generatePDF = async () => {
  //   if (!contentRef.current) return;

  //   const targetDate = new Date();
  //   const formattedDate = `${String(targetDate.getFullYear()).slice(-2)}${String(
  //     targetDate.getMonth() + 1
  //   ).padStart(2, "0")}${String(targetDate.getDate()).padStart(2, "0")}`;
  //   const title = '${formattedDate} - ${property_id.title} - ${  data.company_invoice || "Monthly rentals overview" }';

  //   const options = {
  //     margin: 10,
  //     filename: "title",
  //     image: { type: "jpeg", quality: 1.0 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //   };

  //   // await html2pdf().from(contentRef.current).set(options).save();
  //   // await html2pdf(contentRef.current, options);
  // };
  const styles = {
    headerGrid: {
      marginTop: "10px",
      display: "flex",
      justifyContent: "space-between",
    } as React.CSSProperties,
    addressSection: { lineHeight: "22px", fontSize: "15px" } as React.CSSProperties,
    table: { borderCollapse: "collapse", marginLeft: "5.69292pt", fontSize: "13px" } as React.CSSProperties,
    tableRow: { borderBottomStyle: "solid", borderBottomWidth: "1px", borderColor: "black",paddingBottom:"10px" } as React.CSSProperties,
    bold: { fontWeight: 700 } as React.CSSProperties,
    centered: { textAlign: "center" } as React.CSSProperties,
    totalRow: { borderTopStyle: "solid", borderTopWidth: "2pt" } as React.CSSProperties,
    footer: { marginTop: "10px", justifyContent: "center", display: "flex", fontSize: "13px" } as React.CSSProperties,
  };

  const formattedMonth = String(month).padStart(2, "0");
  const lastDate = moment(`${formattedMonth}/01/${year}`).endOf("month").format("MM/DD/YYYY");
  const period = `${formattedMonth}/01/${year} - ${lastDate}`;
  const total = Number(data?.rent || 0) + Number(data?.city_tax || 0);
  return (
    <div style={{ padding: "20px 30px" }} ref={contentRef}>
      <Grid container>
        <Grid xs={12} style={styles.headerGrid}>
          <div>
            <img width={237} height={61} src={logo} alt="Logo" />
          </div>
          <div style={styles.addressSection}>
            <p style={styles.bold}>EUROPEA Residences SA</p>
            15 Monts des arts
            <br />
            1000 Bruxelles
            <br />
            VAT number: 0643510470
            <br />
            Registered in Belgium No: 0643510470
            <br />
            <a
              href="https://www.europea-residences.com"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                display: 'inline-block', 
                borderBottom: '1px solid black', 
                paddingBottom: '2.5px',
              }}
            >
              https://www.europea-residences.com
            </a>
          </div>
        </Grid>
        <Grid xs={12} style={{ fontSize: "13px" }}>
          <div>Property name: {property_id?.title}</div>
          <div>Accommodation accounting n°: {property_id?.reference_no}</div>
          <div style={{width:"60%"}}>Accommodation address: {property_id?.address}</div>
          <div>Invoice number: {data?.company_invoice}</div>
          <div>Date: {lastDate}</div>
          <div>Period: {period}</div>
        </Grid>
        <Grid xs={12} style={{ ...styles.centered, marginTop: "30px", marginBottom: "30px" }}>
          <h4 style={styles.bold}>Invoice {data?.company_invoice}</h4>
        </Grid>
        <Grid xs={12}>
          <table width={'100%'} style={styles.table} cellSpacing={0}>
            <tbody>
              <tr style={styles.tableRow}>
                <td style={{paddingBottom:"10px"}}>Guest Name</td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>N°Pax</td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>Channel</td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>Stay Dates</td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>RENT (Cleaning fee incl.)</td>
              </tr>
              <tr style={{borderBottom:"1px solid gray", paddingBottom:"10px"}}>
                <td style={{paddingBottom:"10px"}}>{data?.guest_name}</td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>{data?.number_of_person}</td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>{data?.channel_name}</td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>
                  {moment(data?.start_date).format("MM/DD")} - {moment(data?.end_date).format("MM/DD")}
                </td>
                <td style={{...styles.centered, paddingBottom:"10px"}}>EUR {String(data?.rent || 0).replace(".", ",")}</td>
              </tr>
              {Number(data?.city_tax) > 0 && (
                <tr style={{borderBottom:"1px solid gray", paddingBottom:"10px"}}>
                  <td  style={{...styles.bold, paddingBottom:"10px"}}>City Tax</td>
                  <td colSpan={3}></td>
                  <td style={{...styles.centered, paddingBottom:"10px"}}>+ EUR {String(data?.city_tax).replace(".", ",")}</td>
                </tr>
              )}
              <tr style={{...styles.totalRow, paddingTop:"15px"}}>
                <td style={{ ...styles.bold, width: "130px" }}>Total (All tax included)*</td>
                <td colSpan={3}></td>
                <td style={styles.centered}>EUR {String(total.toFixed(2)).replace(".", ",")}</td>
              </tr>
            </tbody>
          </table>
        </Grid>
        <Grid xs={12} style={styles.footer}>
          <p>Payment Due:    {lastDate} {/*Sept 10, 2023*/}

          </p>
        </Grid>
      </Grid>
      {/* <button onClick={generatePDF}>Download PDF</button> */}
    </div>
  );
};

export default ReservationDetails;
