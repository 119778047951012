import React, { FC } from "react";
import LocationInput from "./LocationInput";
import GuestsInput from "./GuestsInput";
import StayDatesRangeInput from "./(stay-search-form)/StayDatesRangeInput";
import { StaySearchFormFields } from "components/HeroSearchForm/type";

export interface StaySearchFormProps {
  defaultFieldFocus?: StaySearchFormFields;
}

const HeroSearchFormHomePage: FC<StaySearchFormProps> = ({ defaultFieldFocus }) => {

  const renderForm = () => {
    return (
      <form className="relative flex rounded-full border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800">
        <LocationInput
          className="flex-[1.5]"
          autoFocus={defaultFieldFocus === "location"}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-6"></div>
        <StayDatesRangeInput className="flex-[1.2]" />

        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-6"></div>
        <GuestsInput
          className="flex-1"
          autoFocus={defaultFieldFocus === "guests"}
          submitLink="/"
        />
      </form>
    );
  };

  return renderForm();
};

export default HeroSearchFormHomePage;
