import React, { Fragment, useState, FC, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomHeaderOneMonth from "components/DatePickerCustomerHeaderOneMonth";
import DatePickerCustomeDay from "components/DatePickerCustomeDay";
import moment from "moment";

export interface StayDatesRangeInputProps {
  className?: string;
  blockedDates?: any;
  setBlockedDates?: any;
  endDateStr?: any;
  startDateStr?: any;
  setStartDateStr?: any;
  setEndDateStr?: any;
  setLoading?: any;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "flex-1",
  blockedDates,
  setBlockedDates,
  endDateStr,
  startDateStr,
  setStartDateStr,
  setEndDateStr,
  setLoading
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChangeDate = (dates: [Date | null, Date | null], close: () => void) => {
    const [start, end] = dates;

    setStartDate(start);
    setEndDate(end);

    // Only update string states if valid dates are selected
    setStartDateStr(start ? moment(start).format("YYYY-MM-DD")  : null);
    setEndDateStr(end ? moment(end).format("YYYY-MM-DD") : null);

    setLoading(false);

    if (start && end) {
      close();
    }
  };

  useEffect(() => {
    if (startDateStr) {
      const parsedStartDate = new Date(startDateStr);
      if (!isNaN(parsedStartDate.getTime())) {
        setStartDate(parsedStartDate);
      }
    }
  }, [startDateStr]);

  useEffect(() => {
    if (endDateStr) {
      const parsedEndDate = new Date(endDateStr);
      if (!isNaN(parsedEndDate.getTime())) {
        setEndDate(parsedEndDate);
      }
    }
  }, [endDateStr]);

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg font-semibold">
            {startDate?.toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
            }) || "Add dates"}
            {endDate
              ? " - " +
              endDate?.toLocaleDateString("en-US", {
                month: "short",
                day: "2-digit",
              })
              : ""}
          </span>
          <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
            {"Check in - Check out"}
          </span>
        </div>
      </>
    );
  };

  return (
    <Popover style={{zIndex:13}} className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`flex-1 flex relative p-3 items-center space-x-3 focus:outline-none ${open ? "shadow-lg" : ""}`}
          >
            {renderInput()}
            {startDate && open && (
              <ClearDataButton onClick={() => onChangeDate([null, null], close)} />
            )}
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-auto xl:-right-10 right-0 z-10 mt-3 top-full w-screen max-w-sm px-4 sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800 p-8">
                <DatePicker
                  selected={startDate}
                  onChange={(dates) => onChangeDate(dates, close)}
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date()}
                  excludeDates={blockedDates}
                  selectsRange
                  monthsShown={2}
                  showPopperArrow={false}
                  inline
                  renderCustomHeader={(p) => (
                    <DatePickerCustomHeaderOneMonth {...p} />
                  )}
                  renderDayContents={(day, date) => (
                    <DatePickerCustomeDay dayOfMonth={day} date={date} />
                  )}
                />
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;