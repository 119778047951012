import React from "react";
import Logo from "shared/Logo/Logo";

const TermsOfUseForTravels = () => {
    return (
        <div>
            <div className="py-10 flex justify-center">
                <Logo />
            </div>
            <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg text-justify">
                <h1 className="text-3xl font-bold text-center mb-12">Online Travel Services - Terms of Use</h1>

                <section className="mb-8 text-justify">
                    <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                    <p className="text-gray-700">
                        Welcome to Europea Residences! These Terms of Use (“Terms”) govern your access to and use of
                        the website {' '}
                        <a href="https://www.europea-residences.com/" className="text-blue-600 underline"> https://www.europea-residences.com</a>
                        {' '}
                        (the “Site”), including any services provided through
                        the Site (the “Services”), all mobile applications and operating systems designed and operated by
                        Europea Residences.
                        <br /> <br />
                        The Terms of Service (“Terms”) are a legally binding contract between you and Europea
                        Residences SA with its corporate headquarters located at 15 Monts des arts, 1000 Brussels.
                        <br /> <br />
                        By using the Platform, you are accepting all the terms and conditions set forth in these Terms. If
                        you do not agree to each and all of these terms and conditions please do not use the Platform and
                        our Services. We reserve the right, at our discretion, to change and or modify these Terms at any
                        time without notice and, unless otherwise indicated, such changes will become effective
                        immediately upon posting; therefore, please check these Terms periodically for changes. We will
                        display the effective date of these Terms at the top of this page.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">2. About Europea Residences</h2>
                    <p className="text-gray-700">
                    <b> Europea Residences SA </b> is a Belgian agency specialising in the online distribution & Management of holiday and serviced apart-hotel accommodations.
                        We offer an online platform where users can
                        browse, organise, and book short- and mid-term accommodations across various cities in Europe,
                        including Paris and Brussels, for both leisure and professional mobility.
                        Europea Residences SA does not own any residence listed on our Platform.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">3. Privacy policy for the use of <a href="https://www.europea-residences.com/" className="text-blue-600 underline"> https://europea-residences.com </a></h2>
                    <p className="text-gray-700">
                        <b>  3.1 Eligibility </b><br />
                        To use the Site and reserve properties, you must be at least 18 years old and capable of entering into
                        legally binding agreements.
                        <br /><br />
                        <b> 3.2 No account Registration / No app for users / No Cookies</b>
                        <br /><br />
                        <b> Europea Residences SA </b> does not require account creation on our online platform. You are free to
                        make a reservation without the need to create an account. Once your reservation is con rmed, you
                        will gain access to a secure online portal that is exclusively dedicated to managing your upcoming
                        stay with us.
                        <br /><br />
                        <b> Europea Residences SA </b> only lists properties that do not require the download of an app for check-
                        in.<br /><br />
                        <b> Europea Residences SA </b>does not use cookies of any sort.<br /><br />
                        <b> Europea Residences SA </b>does not send any newsletters to any of our customers.
                        <br /><br />
                        <b> 3.3 Personal data</b><br />
                        We are committed to keeping your personal data private and secure. We do not use, retarget, or
                        market any personal data of our users.<br />
                        While safeguarding your personal information is a priority, ensuring the security of the houses and
                        residences listed on our platform is equally important. We kindly ask that you to provide at check-in
                        identity information for yourself and any members of your travel party before accessing the
                        properties published on our platform.
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">4. Privacy policy for the use of Europea Mobile apps</h2>
                    <p className="text-gray-700">
                        <b> 4.1 General </b>
                        Appeuropea.com ("us", "we", or "our") operates the different mobile applications of Europea
                        Residences (the “Service") used by our Owners / Partners / Team Members.<br /><br />
                        To improve and provide support, we use minor part of your data. By using the Service, you agree to
                        the collection and use of information in accordance with this policy. Unless otherwise de ned in
                        this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and
                        Conditions.<br /><br />
                        <b>  4.2 Limited Data Collected </b>
                        While using our Service, we may ask you to provide us with certain personally identi able
                        information that can be used to contact or identify you ("Personal Data"). Personally identi able
                        information may include, but is not limited to:<br /><br />
                        <ul className="list-disc pl-5 text-gray-700">
                            <li>Email address</li>
                            <li>First name and last name</li>
                            <li>Phone number</li>
                            <li>Mobile device</li>
                            <li>Type of Browser</li>
                            <li>Unique device identifiers</li>
                            <li>Diagnostic data in case of incident / crash</li>
                        </ul>
                        <br /><br />
                        Examples of Cookies we use:
                        <br /><br />
                        <ul className="list-disc pl-5 text-gray-700">
                            <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                            <li>Security Cookies. We use Security Cookies for security purposes.</li>
                        </ul>
                        <br /><br />
                        <b> 4.3 Use of Data </b>
                        <br /><br />
                        appeuropea.com uses the collected data for various purposes:<br /><br />
                        <ul className="list-disc pl-5 text-gray-700">
                            <li>To provide and maintain the Service</li>
                            <li>To notify you about changes to our Service</li>
                            <li>To provide customer care and support</li>
                            <li>To provide analysis or valuable information so that we can improve the Service</li>
                            <li>To monitor the usage of the Service                        </li>
                            <li>To detect, prevent and address technical issues                        </li>
                        </ul>

                        <br /><br />
                        We do not track your activities, or access any personal information others than those directly related
                        to the use of our Apps
                        <br /><br />
                        <b> 4.4 Transfer Of Data</b><br /><br />
                        Your information, including technical Data, is transferred in 2 locations : Belgian Collection center,
                        and India, Mumbai, Europea IT center. Our Apps use as well the technology of AWS Amazon for
                        the cloud computing aspects.
                        <br /><br />
                        <b>4.5 Disclosure Of Data</b><br /><br />
                        appeuropea.com may disclose your Personal Data to authorities in the good faith belief that such
                        action is necessary to:
                        <br /><br />
                        <ul className="list-disc pl-5 text-gray-700">
                            <li>To comply with a legal obligation</li>
                            <li>To protect and defend the rights or property of appeuropea.com</li>
                            <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li>To protect the personal safety of users of the Service or the public</li>
                            <li>To protect against legal liability</li>
                        </ul>
                        <br /><br />
                        <b> 4.6 Children’s Privacy</b>
                        <br /><br />
                        Our Service does not address anyone under the age of 18 ("Children").
                        <br /><br />
                        <b> 4.7 Changes To This Privacy Policy</b>
                        <br /><br />
                        We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                        the new Privacy Policy on this page.
                        <br /><br />
                        We will let you know via email and/or a prominent notice on our Service, prior to the change
                        becoming effective and update the "effective date" at the top of this Privacy Policy.
                        You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
                        Policy are effective when they are posted on this page.
                        <br /><br />
                    </p>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">5. Online Reservation and Payment</h2> <br /><br />
                    <b>
                        5.1 Reservation Process </b>
                    <br /><br />
                    We believe in automation and direct bookings. All properties listed on our platform offer online
                    booking based on real-time calendar synchronisation.
                    However, despite the use of IA technology to synchronise and block calendars, on rare occasions
                    (approximately 3-4 times per year), calendar synchronisation may fail. In such cases, a full refund
                    will be issued to the client if the upgrade proposal sent by our reservation team does not meet your
                    needs
                    <br /><br />
                    <b> 5.2 Payment Terms</b>
                    <br /><br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li>The payment methods accepted will be displayed during the booking process. You agree to
                            use your credit card for payment, secured by 3D Secure technology. If the credit card used
                            for the payment is not yours, we will require a photo of the credit card and a photo of the
                            cardholder's ID after the reservation is made. Fraud attempts are common, so we kindly ask
                            for your patience during this process.</li>
                        <li>You will be able to choose also SEPA Wire transfer to our Collection Center located in
                            Belgium during the payment process.</li> <li>
                            Full payment may be required at the time of booking, except for stays longer than 3 months,
                            where payment adjustments can be made.</li> <li>
                            Rates includes Rent amount, tax and conciergerie fees. No extra will be required at your
                            check-in.</li><li>
                            In order to keep a record of all nancial ows, our company does not accept cash payments.</li>
                    </ul>
                    <br /><br />
                    <b>  5.3 Cancellations and Refunds</b>  <br /><br />
                    Refund eligibility depends on the speci c cancellation terms applicable to your booking : <br /><br />
                    <ui className="list-disc pl-5 text-gray-700">
                        <li>
                            Free cancelation for a cancelation more than 2 months prior arrival or less than 24h after
                            reservation ( Grace period ).
                        </li>

                        <li> 50% cancelation fees for any cancelation between 2 months and 15 days prior arrival</li> <br />
                        <li> 100% cancelation fees for any cancelation between 15 days and arrival date.</li> <br />
                    </ui>
                    Any refund will be proceed by our accounting team at the latest 10 days after notice of cancelation
                    received.
                    <br /><br />

                    <b> 5.4 Collection of City Tax</b>
                    <br /><br />
                    As an online agency, our company, through its collection center in Belgium, collects the city tax for
                    each destination and transfers the correct amount to the respective municipalities.
                    <br />
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">6. Limitation of Liability</h2>
                    <br />
                    <p className="text-gray-700">
                        Europea Residences strives to provide accurate listings and services with the information provided
                        by the Owners / Responsible of the residences, but we do not guarantee that all information is error-
                        free.
                    </p>


                    <br /><br />
                    We are not responsible for:
                    <br /><br />

                    <ul className="list-disc pl-5 text-gray-700">
                        <li> Interruptions or issues related to website functionality.</li>
                        <li> Unforeseen events that may affect your stay (e.g., force majeure, emergencies).</li>
                        <li>  Legal information regarding the status or classi cation of a residence (Hotel, Aparthotel,
                            Apartment) is provided by the property owners or partners. The owners' declarations are
                            considered authoritative, and our agency is not able to verify third-party compliance with
                            applicable laws. We trust that owners and partners provide information in good faith, and we
                            publish it as declared.</li> <li>
                            General issues related to the neighbourhood or neighbours in the building.</li><li>
                            Issues related to works in the neighbourhood or in the common area.</li><li>
                            Injuries that occurs during your stay or on your way to your stay accommodation.</li>
                    </ul>
                </section>

                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-2">7. Governing Law and Dispute Resolution</h2>
                    <p className="text-gray-700">
                        These Terms are governed by the laws of the Kingdom of Belgium.
                    </p>
                </section>
            </div>
        </div>
    );
};

export default TermsOfUseForTravels;
