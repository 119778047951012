import React, { Fragment, useState } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Slider from "rc-slider";
import convertNumbThousand from "utils/convertNumbThousand";


const moreFilter3 = [
  { name: "House" },
  { name: "Apartment" },
  { name: "Hotel Room" },
  { name: "Commercial Unit" },
  { name: "Apart-hotel " },
  { name: "Primary residence" },
  { name: "Secondary residence" },
];

const Accessibility = [
  { name: "Wheelchair access" },
  { name: "Handicapped rooms facilities" },
  { name: "Elevator" },
  { name: "Braille Elevator" },
  { name: "Accessible path of travel" },
];

const moreFilter4 = [{ name: " Pets allowed" }, { name: "Smoking allowed" }];

const TabFiltersNew = (props: any) => {
  const { count, fetchProperties, setFilterValues, filterValues, selectedFilters, setSelectedFilters, rangePrices, setRangePrices } = props;
  const [isOpenMoreFilterMobile, setisOpenMoreFilterMobile] = useState(false);

  const handleFilterChange = (key: string, value: any) => {
    setFilterValues((prev: any) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleFilterChanges = (key: string, checked: boolean, name: string) => {
    setFilterValues((prev: any) => {
      const updatedKeyValues = checked
        ? [...(prev[key] || []), name]
        : (prev[key] || []).filter((type: string) => type !== name);

      return {
        ...prev,
        [key]: updatedKeyValues,
      };
    });
  };
  const applyFilters = async () => {
    
    sessionStorage.setItem('filterValues', JSON.stringify(filterValues));
    const finalFilters = {
      ...filterValues,
    };
    console.log("Filters applied:", finalFilters);
    await fetchProperties(finalFilters);
    closeModalMoreFilterMobile();
  };

  const clearFilters = () => {
    setRangePrices([0, 1000]);
    const filterVal = {
      typeOfPlace: [],
      toilets: 0,
      bedrooms: 0,
      bathrooms: 0,
      propertyType: [],
      houseRules: [],
      accessibility: [],
      rangePrices: [0, 1000],
    }
    sessionStorage.removeItem("filterValues");
    setFilterValues(filterVal);
    fetchProperties(filterVal);
  };

  const closeModalMoreFilterMobile = () => {
    setisOpenMoreFilterMobile(false);
  };
  const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderMoreFilterItem = (
    data: {
      name: string;
      defaultChecked?: boolean;
    }[],
    label: string,
    defualtValue: any
  ) => {
    const list1 = data.filter((_, i) => i < data.length / 2);
    const list2 = data.filter((_, i) => i >= data.length / 2);
    console.log("label", label);
    return (
      <div className="grid grid-cols-2 gap-8">
        <div className="flex flex-col space-y-5">
          {list1.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              label={item.name}
              defaultChecked={defualtValue.includes(item.name) ? true : false}
              onChange={(val: any) =>
                handleFilterChanges(label, val, item.name)
              }
            />
          ))}
        </div>
        <div className="flex flex-col space-y-5">
          {list2.map((item) => (
            <Checkbox
              key={item.name}
              name={item.name}
              label={item.name}
              defaultChecked={defualtValue.includes(item.name) ? true : false}
              onChange={(val: any) =>
                handleFilterChanges(label, val, item.name)
              }
            />
          ))}
        </div>
      </div>
    );
  };

  const renderTabMoreFilterMobile = () => {
    return (
      <div>
        <div
          className={`flex items-end justify-end px-4 py-2 text-sm text-black rounded-full border border-primary-500 bg-primary-50 text-primary-700 focus:outline-none cursor-pointer`}
          onClick={openModalMoreFilterMobile}
        >
          <span className="text-black">Filters</span>
          {/* {renderXClear()} */}
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Type of place</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(typeOfPaces)}
                        </div>
                      </div> */}

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Set a budget for your stay.</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={0}
                                max={2000}
                                value={filterValues.rangePrices}
                                defaultValue={[0, 1000]}
                                allowCross={false}
                                onChange={(e) => handleFilterChange("rangePrices",e as number[])}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      €
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={filterValues.rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      €
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={filterValues.rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Rooms and beds</h3>
                        <div className="mt-6 relative flex flex-col space-y-5">
                          <NcInputNumber
                            label="Beds"
                            max={10}
                            defaultValue={filterValues.toilets}
                            onChange={(val) => handleFilterChange("toilets", val)}
                          />
                          <NcInputNumber
                            label="Bedrooms"
                            max={10}
                            defaultValue={filterValues.bedrooms}
                            onChange={(val) =>
                              handleFilterChange("bedrooms", val)
                            }
                          />
                          <NcInputNumber
                            label="Bathrooms"
                            max={10}
                            defaultValue={filterValues.bathrooms}
                            onChange={(val) =>
                              handleFilterChange("bathrooms", val)
                            }
                          />
                        </div>
                      </div>

                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter1)}
                        </div>
                      </div> */}

                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Facilities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter2)}
                        </div>
                      </div> */}

                      <div className="py-7">
                        <h3 className="text-xl font-medium">Property type</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(
                            moreFilter3,
                            "propertyType",
                            filterValues.propertyType
                          )}
                        </div>
                      </div>
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">House rules</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter4, "houseRules", filterValues.houseRules)}
                        </div>
                      </div> */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Accessibility</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(Accessibility, "accessibility", filterValues.accessibility)}
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        closeModalMoreFilterMobile();
                        clearFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        applyFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };
  const popularFilters: any = [
    // "Pets Allowed",
    'Family friendly',
    'Pets friendly',
    'Middle term stay'
  ];

  const renderTabMoreFilterMobile2 = () => {

    const toggleFilter = (filter: string) => {
      setSelectedFilters((prev: any) => {
        const updatedFilters = prev.includes(filter)
          ? prev.filter((item: any) => item !== filter)
          : [...prev, filter];
    
        sessionStorage.setItem("selectedFilters", JSON.stringify(updatedFilters));
    
        return updatedFilters;
      });
    };

    const isSelected = (filter: string) => selectedFilters.includes(filter);

    return (
      <div className="flex justify-end align-right">
        <div className="flex flex-col w-full">
          <div className="flex font-semibold text-lg justify-between text-gray-600">
            <div className="text-xs">Popular Filters</div>
            <div className="text-xs align-end">{count} homes</div>
          </div>
          <div className="flex col-12 gap-2 mt-2 justify-between text-gray-500">
            <div className="flex space-x-2 sm:flex-nowrap">
              {popularFilters.map((value: any, index: number) => (
                <div
                  key={index}
                  className={`flex items-center justify-start px-4 py-2 text-xs sm:text-sm rounded-lg border ${isSelected(value)
                      ? "bg-primary-500 text-white border-primary-500"
                      : "bg-primary-50 text-gray-500 border-gray-500"
                    } hover:bg-gray-100 hover:text-black focus:outline-none cursor-pointer`}
                  onClick={() => toggleFilter(value)}
                >
                  <span>{value}</span>
                </div>
              ))}
            </div>
            <div
              className={`flex  item-center justify-center lg:items-end lg:justify-end py-4 px-1 lg:px-4 lg:py-2 text-xs sm:text-sm text-black rounded-lg border border-gray-400 bg-primary-50 hover:bg-gray-100 text-primary-700 focus:outline-none cursor-pointer`}
              onClick={openModalMoreFilterMobile}
            >
              <svg
                viewBox="0 0 16 16"
                aria-hidden="true"
                role="presentation"
                focusable="false"
                className="block w-6 h-6"
                fill="black"
              >
                <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"></path>
              </svg>
              &nbsp;
              <span className="text-black">Filter & Sort</span>
              {/* {renderXClear()} */}
            </div>
          </div>
        </div>

        <Transition appear show={isOpenMoreFilterMobile} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilterMobile}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 px-2 h-screen w-full max-w-4xl"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Add filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilterMobile} />
                    </span>
                  </div>

                  <div className="flex-grow overflow-y-auto">
                    <div className="px-4 sm:px-6 divide-y divide-neutral-200 dark:divide-neutral-800">
                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Type of place</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(typeOfPaces)}
                        </div>
                      </div> */}

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Set a budget for your stay.</h3>
                        <div className="mt-6 relative ">
                          <div className="relative flex flex-col space-y-8">
                            <div className="space-y-5">
                              <Slider
                                range
                                className="text-red-400"
                                min={0}
                                max={10000}
                                value={filterValues.rangePrices}
                                defaultValue={[0, 1000]}
                                allowCross={false}
                                onChange={(e) => handleFilterChange("rangePrices",e as number[])}
                              />
                            </div>

                            <div className="flex justify-between space-x-5">
                              <div>
                                <label
                                  htmlFor="minPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Min price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      €
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    name="minPrice"
                                    disabled
                                    id="minPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={filterValues.rangePrices[0]}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="maxPrice"
                                  className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                                >
                                  Max price
                                </label>
                                <div className="mt-1 relative rounded-md">
                                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <span className="text-neutral-500 sm:text-sm">
                                      €
                                    </span>
                                  </div>
                                  <input
                                    type="text"
                                    disabled
                                    name="maxPrice"
                                    id="maxPrice"
                                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                    value={filterValues.rangePrices[1]}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* ---- */}
                      <div className="py-7">
                        <h3 className="text-xl font-medium">Rooms and beds</h3>
                        <div className="mt-6 relative flex flex-col space-y-5">
                          <NcInputNumber
                            label="Toilets"
                            max={10}
                            defaultValue={filterValues.toilets}
                            onChange={(val) => handleFilterChange("toilets", val)}
                          />
                          <NcInputNumber
                            label="Bedrooms"
                            max={10}
                            defaultValue={filterValues.bedrooms}
                            onChange={(val) =>
                              handleFilterChange("bedrooms", val)
                            }
                          />
                          <NcInputNumber
                            label="Bathrooms"
                            max={10}
                            defaultValue={filterValues.bathrooms}
                            onChange={(val) =>
                              handleFilterChange("bathrooms", val)
                            }
                          />
                        </div>
                      </div>

                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Amenities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter1)}
                        </div>
                      </div> */}

                      {/* ---- */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Facilities</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter2)}
                        </div>
                      </div> */}

                      <div className="py-7">
                        <h3 className="text-xl font-medium">Property type</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(
                            moreFilter3,
                            "propertyType",
                            filterValues.propertyType
                          )}
                        </div>
                      </div>
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">House rules</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(moreFilter4, "houseRules", filterValues.houseRules)}
                        </div>
                      </div> */}
                      {/* <div className="py-7">
                        <h3 className="text-xl font-medium">Accessibility</h3>
                        <div className="mt-6 relative ">
                          {renderMoreFilterItem(Accessibility, "accessibility", filterValues.accessibility)}
                        </div>
                      </div> */}
                    </div>
                  </div>

                  <div className="p-4 sm:p-6 flex-shrink-0 bg-neutral-50 dark:bg-neutral-900 dark:border-t dark:border-neutral-800 flex items-center justify-between">
                    <ButtonThird
                      onClick={() => {
                        closeModalMoreFilterMobile();
                        clearFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Clear
                    </ButtonThird>
                    <ButtonPrimary
                      onClick={() => {
                        applyFilters();
                      }}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonPrimary>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="pb-2 ">
      {/* <div className="lg:flex space-x-4 pt-1 pb-4 justify-between"> */}
      {/* {renderTabsTypeOfPlace()}
        {renderTabsPriceRage()}
        {renderTabsRoomAndBeds()}
        {renderTabMoreFilter()} */}
      {/* {renderTabMoreFilterMobile()} */}
      {/* {renderFilterSelected()} */}
      {renderTabMoreFilterMobile2()}
      {/* </div> */}
      {/* {renderTabMoreFilterMobile()} */}
    </div>
  );
};

export default TabFiltersNew;
