import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import BtnLikeIcon2 from "components/BtnLikeIcon/BtnLikeIcon2";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface propertyCardProps {
  className?: string;
  ratioClass?: string;
  data?: ExperiencesDataType;
  size?: "default" | "small";
  id?: string;
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const propertyCard: FC<propertyCardProps> = ({
  size = "default",
  className = "",
  id,
  data = DEMO_DATA,
  ratioClass = "aspect-w-14 aspect-h-9",
}) => {
  const {
    _id,
    galleryImgs,
    avgRatePerDay,
    address,
    title,
    href,
    like,
    base_price,
    bedrooms, bathrooms, toilets,
    finalTotal,
    residence_type,
    location
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
        <GallerySlider
          uniqueID={`${_id}`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
          href={href}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent2 = () => {
    return (
      <div className="flex-col p-2">
        <div className="flex justify-between text-xs items-center">
          <div>
            <span
              style={{
                padding: "4px 8px",
                color: "white",
                backgroundColor: "#DBAE74",
                borderRadius: "40px",
              }}
            >
               {residence_type}
            </span>
            <span className="ml-1 text-xs">
              <i className="las la-map-marker-alt la-md"></i> {location}
            </span>
          </div>
          {/* <BtnLikeIcon2 isLiked={like} className="transform scale-85" /> */}
        </div>

        <div className="flex-col mt-2">
          <h2 className="text-black text-[16px]">{title}</h2>
          <span className="text-xs"> {bedrooms} {bedrooms && bedrooms>1 ? ' Bedrooms' : ' Bedroom' } . {' '}
            {bathrooms} {bathrooms && bathrooms>1 ? ' Bathrooms' : ' Bathroom' } . {' '}
             {toilets} {toilets && toilets>1 ? ' Toilets' : ' Toilet' }</span>
        </div>
        <hr className="border-t-2 border-grey my-2 " />
        <div className="flex items-start gap-6 text-xs">
          <div className="flex items-start">
          <span className="text-md font-bold mr-1"> € {avgRatePerDay?avgRatePerDay.toFixed(2):base_price}</span>
            <span>night</span>
          </div>
          <ul className="list-disc inline-block underline text-gray">
            <li>
            { finalTotal&&<span>€{finalTotal.toFixed(2)} Total</span>}
            </li>
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`nc-ExperiencesCard group relative bg-white rounded-2xl ${className}`}
      data-nc-id="ExperiencesCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent2()}</Link>
    </div>
  );
};

export default propertyCard;
