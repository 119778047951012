import React, { createContext, useContext, useState, ReactNode, useRef } from "react";

interface FilterState {
  checkInDate: Date | null;
  checkOutDate: Date | null;
  guestNumber: number;
  number_of_children:number,
  number_of_infants:number,
  location: string | null;
  handleSubmit: boolean |false
}

interface AppState {
  city: string | null;
  user: string | null;
  totalAmout:number | null;
  setTotalAmount: (user: number | null) => void;
  isAuthenticated: boolean;
  setUser: (user: string | null) => void;
  setAuthenticated: (isAuthenticated: boolean) => void;
  setCity: (user: string | null) => void;
  filterState: FilterState | null;
  setFilterState: (filterState: FilterState) => void;
  updateFilterState:any;
  updateFilterValue:any;
  handleReserveClick:any;
  paymentSectionRef:any;
  filterValues: FilterValues; 
  setFilterValues: (values: FilterValues) => void; 
  resetFilter:any;
  setResetFilter:any;
}

interface FilterValues {
  toilets: number;
  bedrooms: number;
  bathrooms: number;
  propertyType: string[];
  rangePrices: string[];
}

const AppContext = createContext<AppState | undefined>(undefined);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const getStoredFilterState = (): FilterState => {
    const storedFilters = sessionStorage.getItem("filterData");
    return storedFilters
      ? JSON.parse(storedFilters)
      : {
          checkInDate: null,
          checkOutDate: null,
          guestNumber: 1,
          number_of_children: 0,
          number_of_infants: 0,
          location: "Paris",
          handleSubmit: false,
        };
  };

  const getStoredFilterValue = (): FilterValues => {
    const storedFilters = sessionStorage.getItem("filterValues");
    return storedFilters
      ? JSON.parse(storedFilters)
      : {
        toilets: 0,
        bedrooms: 0,
        bathrooms: 0,
        propertyType: [],
        rangePrices:[0,1000]
        };
  };

  const [user, setUser] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>('Paris');
  const [resetFilter, setResetFilter] = useState(false);

  const [totalAmout, setTotalAmount] = useState<number | null>(0);

  const [isAuthenticated, setAuthenticated] = useState(false);
  const [filterState, setFilterState] = useState<FilterState>(getStoredFilterState());
  const [filterValues, setFilterValues] = useState<FilterValues>(getStoredFilterValue());

  const updateFilterState = (key: keyof FilterState, value: any) => {
    setFilterState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const updateFilterValue = (key: keyof FilterState, value: any) => {
    setFilterValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const paymentSectionRef = useRef<HTMLDivElement | null>(null);

  const handleReserveClick = () => {
    paymentSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <AppContext.Provider value={{ handleReserveClick,paymentSectionRef, totalAmout, 
    setTotalAmount, city, setCity, user, isAuthenticated, setUser, setAuthenticated,
    filterState, setFilterState, updateFilterState, filterValues, setFilterValues, updateFilterValue, setResetFilter, resetFilter }}>
      {children}
    </AppContext.Provider>
  );
};


export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};