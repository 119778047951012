import DatePicker from "react-datepicker";
import React, { FC, useEffect, useState } from "react";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import { useAppContext } from "AppContext";
import moment from "moment";

export interface StayDatesRangeInputProps {
  className?: string;
  setFieldNameShow?:any;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "",
  setFieldNameShow
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const { updateFilterState, filterState } = useAppContext();
  
  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    updateFilterState("checkInDate", start ? moment(start).format("YYYY-MM-DD") : null)
    updateFilterState("checkOutDate", end ? moment(end).format("YYYY-MM-DD") : null)
    if(start && end){
      setFieldNameShow('guests')
    }
  };

  useEffect(()=>{
    let date = filterState?.checkInDate ? new Date(filterState.checkInDate) : null
    setStartDate(date);
  },[filterState?.checkInDate])

  useEffect(()=>{
    let end = filterState?.checkOutDate ? new Date(filterState.checkOutDate) : null
    setEndDate(end);
  },[filterState?.checkOutDate])
  
  return (
    <div>
      <div className="p-5">
        <span className="block font-semibold text-xl sm:text-2xl">
          {` When's your trip?`}
        </span>
      </div>
      <div
        className={`relative flex-shrink-0 flex justify-center z-10 py-5 ${className} `}
      >
        <DatePicker
          selected={startDate}
          onChange={onChangeDate}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          monthsShown={2}
          showPopperArrow={false}
          inline
          renderCustomHeader={(p) => <DatePickerCustomHeaderTwoMonth {...p} />}
          renderDayContents={(day, date) => (
            <DatePickerCustomDay dayOfMonth={day} date={date} />
          )}
        />
      </div>
    </div>
  );
};

export default StayDatesRangeInput;
