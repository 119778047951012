import React from "react";
import Logo from "shared/Logo/Logo";

const TermsOfUse = () => {
    return (<>

        <div className="py-10 flex justify-center">
            <Logo />
        </div>
        <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg text-justify">
            <h1 className="text-3xl font-bold text-center mb-12">Residences Terms of Use</h1>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                <p className="text-gray-700">
                    By booking a property on our Online platform <b> Europea Residences SA</b>, you are accepting all the
                    terms and conditions set forth in these local Terms of use of your reserved residence.
                    <br /> <br />
                    If you do not agree to each and all of these terms and conditions please do not use the Platform and
                    our Services. We reserve the right, at our discretion, to change and or modify these Residences
                    Terms at any time without notice and, unless otherwise indicated, such changes will become
                    effective immediately upon posting; therefore, please check these Terms periodically for changes.
                    We will display the effective date of these Terms at the top of this page.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">2. About Europea Residences</h2> <br /> 
                <p className="text-gray-700">
                    <b> Europea Residences SA </b> is a Belgian agency specialising in the online distribution & Management 
                    of holiday and serviced apart-hotel accommodations. We offer an online platform where users can browse,
                    organise, and book short- and mid-term accommodations across various cities in Europe, including
                    Paris and Brussels, for both leisure and professional mobility.
                    <br /> <br />
                    Europea Residences SA does not own any residence listed on our Platform.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">3. About the Residences</h2>
                <p className="text-gray-700">
                    Each residence can be an Hotel Room, an Apart-Hotel, or an Apartment ( type Airbnb ) and
                    provided in good faith as such. <br /> <br />
                    Each unit is provided and controlled by owners or legal responsible and provided in good faith as
                    such.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">4. Residences Rules</h2> <br /> <br />
                <p>
                    By making a reservation and staying at one of the published properties, you agree to comply with
                    the following residence rules. These rules are in place to ensure a safe, peaceful, and enjoyable stay
                    for all guests while respecting the property itself, the Owners belongings, and the peace of our
                    neighbours.
                </p>
                <p className="text-gray-700">
                    <br /><br />
                    <b> 4.1 Check-In and Check-Out </b>
                    <br /> <br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li> <b> Check-in time </b>is usually between 3:00pm and 4:00pm, depending the size and location of
                            the residence.</li>
                        <li><b>Check-out time</b> is 11:00 am.</li>
                        <li><b>Early check in : </b>Requests must be communicated in advance to our reservation team. We
                            always do our best to allow early check-in when possible. Early check-ins are free of charge.
                            Please note that the earliest time we can accommodate a check-in is 10:00 a.m.</li>
                        <li><b>Late check out : </b>Requests must be communicated to our reservation team the day before
                            departure. We always do our best to allow a late check-out when possible. Late check-outs
                            are free of charge. Please note that the latest time we can accommodate a late check-out is
                            6:00 p.m.</li>
                        <li><b>Check-in methods :</b> The check-in details will be communicated a few days prior to your
                            arrival. If you have any speci c requests related to check-in, please let us know in advance.</li>
                    </ul>
                    <br /> <br />
                    <b>  4.2 Maximum Occupancy </b>
                    <br /> <br />
                    <p>
                        The maximum number of guests allowed in the residence is determined by the Owners and stated
                        on the online listing. Hosting more guests than the number speci ed in the listing is unauthorised.
                        Any such requests must be approved in advance by the Owners of the property.
                    </p>
                    <br /> <br />
                    <b> 4.3 No Smoking Policy </b>
                    <br /><br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li>Smoking is strictly prohibited inside each property.</li>
                        <li>Failure to comply will result in a ne of 500 € to cover cleaning and deodorization costs.</li>
                        <li>An exception is allowed for properties with a garden or large terrace, but smoking is
                            permitted strictly in outdoor areas only</li>

                    </ul>

                    <br /><br />
                    We do not track your activities, or access any personal information others than those directly related
                    to the use of our Apps
                    <br /><br />
                    <b> 4.4 No Parties or Events</b><br /><br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li>Parties, events, or any loud gatherings are strictly prohibited.</li>
                        <li>Violation of this policy may result in immediate termination of the stay without a refund and
                            potential fines.</li>
                        <li>Professional shootings or videos are strictly prohibited in any residence.</li>

                    </ul>
                    <br /><br />

                    <b> 4.5 Quiet Hours</b><br /><br />

                    <ul className="list-disc pl-5 text-gray-700">
                        <li>Quiet hours are from 9:00pm to 7:00am for all the residences.</li>
                        <li>Tenants are expected to be respectful of neighbours and maintain a peaceful environment at
                            all times.</li>

                    </ul>
                    <br /><br />
                    <b> 4.6 Property Care and Maintenance</b>
                    <br /><br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li> Tenants must take care of the property and its contents. Some residences are primary homes,
                            so Tenants must respect the Owners' belongings and pay special attention to personal items,
                            just as you would expect others to respect yours.
                        </li>
                        <li>
                            Tenants must understand that some owners live in their property for most of the year, so they
                            should be understanding of the presence of personal items in some residences. However, the
                            owners always ensure there is space available for tenants to store their belongings during
                            their stay. </li>
                        <li>
                            Any damage, spills, or maintenance issues must be reported immediately. </li>
                        <li> Tenants are responsible for the cost of any damage caused during their stay.</li>
                    </ul>
                    <br /><br />
                    <b> 4.7 Garbage and Recycling</b>
                    <br /><br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li>
                            Garbage and recycling should be disposed of in designated bins.
                        </li>
                        <li>
                            Tenants must follow local waste disposal guidelines.
                        </li>
                    </ul>
                    <br /><br />

                    <b>  4.8 Pets </b>
                    <br /><br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li> Pets are not allowed in most residences </li>
                        <li> However, we love pets and we always strive to offer some pet-friendly residences. We have
                            added lters online to help visitors easily nd these speci c properties. Feel free to send us a
                            request, and we will check with the owners on your behalf. </li>
                    </ul>
                    <br /><br />
                    <b> 4.9 Security and Safety </b>
                    <br /><br />
                    <ul className="list-disc pl-5 text-gray-700">
                        <li> Please ensure all doors and windows are locked when leaving the property.</li>
                        <li> Please do not tamper with or disable any safety equipment.</li>
                    </ul>
                    <br /><br />
                    <b> 4.10 Energy Use </b>
                    <br /><br />
                    Tenants should use energy responsibly by switching off heating and lights when they are not at the
                    residence.
                    <br /><br />

                    <b> 4.11 Compliance with Laws and Regulations </b>
                    <br /><br />
                    Tenants must comply with all applicable local laws and regulations during their stay.
                    <br /><br />
                    <b>4.12 Right of cancelation in certain cases </b>
                    <br /><br />
                    Europea Residences and the Owners reserves the right to cancel any reservation if there is any
                    suspicion of illegal activities or potential risk to the property or its neighbours. For instance if
                    Tenants declines to show identi cation paper at check-in or before check-in.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">5. Limitation of Liability</h2> <br />
                Europea Residences strives to provide accurate listings and services with the information provided
                by the Owners / Responsible of the residences, but we do not guarantee that all information is error-
                free. <br /><br />
                We are not responsible for:
                <br /><br />

                <ul className="list-disc pl-5 text-gray-700">
                    <li>

                        Interruptions or issues related to transportation to or from the Destination.</li><li>
                        Unforeseen events that may affect your stay (e.g., force majeure, emergencies).</li><li>
                        Legal information regarding the status or classi cation of a residence (Hotel, Aparthotel,
                        Apartment….) is provided by the property owners or partners. The owners' declarations are
                        considered authoritative, and our agency is not able to verify third-party compliance with all
                        applicable laws. We trust that owners and partners provide information in good faith, and we
                        publish it as declared.</li><li>
                        Injuries that occurs during your stay or on your way to your stay accommodation.
                    </li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">6.Governing Law and Dispute Resolution</h2>
                <br />
                <p className="text-gray-700">
                    These Residence Terms are governed by the laws of the property location, France if the residence is
                    located in France, Belgium if the residence is located in Belgium, etc.
                </p>
                <br /><br />

            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-2">7.Contact Us</h2>
                <p className="text-gray-700">
                    For any questions regarding these Terms, please contact us via the contact form.
                </p>
            </section>
        </div>
    </>

    );
};

export default TermsOfUse;