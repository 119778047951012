import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

const DatePickerCustomHeaderSingleMonth = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div className="flex items-center justify-between px-4 py-2">
      {/* Previous Month Button */}
      <button
        aria-label="Previous Month"
        className="react-datepicker__navigation react-datepicker__navigation--previous flex items-center justify-center p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
        onClick={decreaseMonth}
        type="button"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
          <ChevronLeftIcon className="w-5 h-5" />
        </span>
      </button>
      
      {/* Current Month and Year */}
      <span className="react-datepicker__current-month text-lg font-medium">
        {monthDate.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        })}
      </span>
      
      {/* Next Month Button */}
      <button
        aria-label="Next Month"
        className="react-datepicker__navigation react-datepicker__navigation--next flex items-center justify-center p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
        onClick={increaseMonth}
        type="button"
      >
        <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
          <ChevronRightIcon className="w-5 h-5" />
        </span>
      </button>
    </div>
  );
};

export default DatePickerCustomHeaderSingleMonth;
