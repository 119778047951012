// import React from "react";
// import ReactDOM from "react-dom/client";
// // import ReactDOM from "react-dom";
// //
// import "rc-slider/assets/index.css";
// // STYLE
// import "./styles/index.scss";
// import "./index.css";
// import "./fonts/line-awesome-1.3.0/css/line-awesome.css";

// //
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";

// ReactDOM.render(
//   <App />,
//   document.getElementById("root")
// );

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );

// const MemoizedApp = React.memo(App);

// root.render(
//   // <React.StrictMode>
//   <MemoizedApp />
//   // </React.StrictMode> */}
// );

import React from "react";
import { createRoot } from "react-dom/client";
import "rc-slider/assets/index.css";
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import App from "./App";

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

const root = createRoot(rootElement);

// Disable React 18's automatic batching for this app
root.render(
  <App />
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
