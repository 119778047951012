import React from 'react';

interface ImageSectionProps {
  imageUrl: string;
  text: string;
  isActive: boolean;
  onClick: () => void;
}

const ImageSection: React.FC<ImageSectionProps> = ({ imageUrl, text, isActive, onClick }) => {
  return (
    <div
      className={`relative w-full h-60 md:h-72 bg-cover bg-center rounded-lg overflow-hidden shadow-md cursor-pointer 
                  ${isActive ? 'border-4 border-[#0c586e]' : ''}`}
      style={{ backgroundImage: `url(${imageUrl})` }}
      onClick={onClick}
    >
      <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
        <h2 style={{letterSpacing:"-1px"}} className="text-white font-semibold text-2xl">{text}</h2>
      </div>
    </div>
  );
};

export default ImageSection;
