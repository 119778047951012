import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import BtnLikeIcon2 from "components/BtnLikeIcon/BtnLikeIcon2";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface propertyCardProps {
  className?: string;
  ratioClass?: string;
  data?: ExperiencesDataType;
  size?: "default" | "small";
  id?: string;
}

const DEMO_DATA: ExperiencesDataType = DEMO_EXPERIENCES_LISTINGS[0];

const propertyCard: FC<propertyCardProps> = ({
  size = "default",
  className = "",
  id,
  data = DEMO_DATA,
  ratioClass = "aspect-w-14 aspect-h-9",
}) => {
  const {
    galleryImgs,
    address,
    title,
    href,
    like,
    avgRatePerDay,
    base_price,
    bedrooms, bathrooms, toilets,
    finalTotal,
    residence_type,
    sleeps,
    location
    
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
        <GallerySlider
          uniqueID={`${id}`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
          href={href}
        />
        {/* <BtnLikeIcon isLiked={like} className="absolute right-3 top-3" /> */}
        {/* {saleOff && <SaleOffBadge className="absolute left-3 top-3" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "py-4 space-y-4" : "py-3 space-y-2"}>
        <div className="space-y-2">
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
            {size === "default" && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
            )}
            <span className="">{address}</span>
          </div>

          <div className="flex items-center space-x-2">
            {/* {isAds && <Badge name="ADS" color="green" />} */}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-base" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <span className="text-base font-semibold">
            {/* {price} */}
            {/* 5
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                Guest
              </span>
            )} */}
          </span>
          {/* <StartRating reviewCount={reviewCount} point={reviewStart} /> */}
        </div>
        <div className="border-b border-[rgb(204, 204, 204)] dark:border-neutral-800"></div>
      </div>
    );
  };
  const renderContent2 = () => {
    return (
      <div className="flex-col pt-4">
        <div className="flex justify-between text-xs items-center">
          <div>
            <span
              style={{
                padding: "4px 7px",
                color: "white",
                backgroundColor: "#DBAE74",
                borderRadius: "40px",
              }}
            >
              {residence_type}
            </span>
            <span className="ml-1 text-xs">
              <i className="las la-map-marker-alt la-md"></i>{location}
            </span>
          </div>
          <span>
            sleeps {sleeps}
          </span>
          {/* <BtnLikeIcon2 isLiked={like} className="transform scale-85" /> */}
        </div>

        <div className="flex-col mt-2">
          <h2 className="text-black">{title}</h2>
          <span className="text-xs"> {bedrooms} {bedrooms && bedrooms>1 ? ' Bedrooms' : ' Bedroom' } . {""}
            {bathrooms} {bathrooms && bathrooms>1 ? ' Bathrooms' : ' Bathroom' } . {""}
             {toilets} {toilets && toilets>1 ? ' Toilets' : ' Toilet' }</span>
        </div>
        <hr className="border-t-2 border-grey my-2 " />
        <div className="flex items-start gap-6 text-xs">
          <div className="flex items-start">
            <span className="text-md font-bold mr-1"> € {avgRatePerDay?avgRatePerDay.toFixed(2):base_price}</span>
            <span>night</span>
          </div>
          <ul className="list-disc inline-block underline text-gray">
            <li>
             { finalTotal&&<span>€{finalTotal.toFixed(2)} Total</span>}
            </li>
          </ul>
        </div>
      </div>
    );
  };
  return (
    <div
      className={`nc-ExperiencesCard group relative ${className}`}
      data-nc-id="ExperiencesCard"
    >
      {renderSliderGallery()}
      <Link to={href}>{renderContent2()}</Link>
    </div>
  );
};

export default propertyCard;
