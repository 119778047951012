import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

type ContactFormModalProps = {
  isOpen: boolean;
  onClose: () => void;
  setFormValues?: any;
  formValues: any;
  bookProperty: any
};


type FormErrors = {
  name?: string;
  email?: string;
  isChecked?: string;
};

const ContactFormModal: React.FC<ContactFormModalProps> = ({ isOpen, onClose, setFormValues, formValues, bookProperty }) => {

  const [errors, setErrors] = useState<FormErrors>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormValues({ ...formValues, [name]: type === "checkbox" ? checked : value });
  };


  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: FormErrors = {};

    if (!formValues.name.trim()) {
      newErrors.name = "Name is required";
    }
    if (!formValues.email.trim()) {
      newErrors.email = "Email is required";
    }
    if (!formValues.isChecked) {
      newErrors.isChecked = "Please accept the terms of service to continue.";
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors({});
      console.log("Form Submitted:", formValues);
      onClose();
    }
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-y-auto" onClose={onClose}>
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
          </Transition.Child>

          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            className="inline-block py-8 px-2 h-screen w-full max-w-md"
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-flex flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 shadow-xl">
              <div className="relative px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                  Contact details
                </Dialog.Title>
                <button
                  className="absolute left-3 top-3 focus:outline-none"
                  onClick={onClose}
                >
                  &#x2715;
                </button>
              </div>

              <form onSubmit={handleSubmit} className="px-6 py-4 space-y-4">
                <div>
                  <label htmlFor="name" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formValues.name}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
                </div>

                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formValues.email}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                  {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
                </div>

                <div>
                  <label htmlFor="phone" className="block text-sm font-medium text-neutral-700 dark:text-neutral-300">
                    Phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    value={formValues.phone}
                    onChange={handleChange}
                    className="mt-1 block w-full px-3 py-2 border rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div>
                  <div className="flex items-start space-x-2">
                    <input
                      type="checkbox"
                      id="isChecked"
                      name="isChecked"
                      checked={formValues.isChecked || false}
                      onChange={handleChange}
                      className="w-4 h-4 cursor-pointer mt-1"
                    />
                    <label htmlFor="isChecked" className="text-sm text-gray-600 leading-tight">
                      I acknowledge and agree with the Europea {" "}
                      <a target="_blank" href="/travel-terms-of-use" className="text-blue-600 underline">
                      Online Travel Services
                      Terms of Use.
                      </a>
                      and <a target="_blank" href="/terms-of-use" className="text-blue-600 underline"> Residences Terms of Service </a>
                    </label>
                  </div>
                  {errors.isChecked && <p className="text-red-500 text-xs mt-1">{errors.isChecked}</p>}
                </div>


                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    className="px-4 py-2 bg-gray-300 rounded-full"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-[#bf8c4c] text-white rounded-full"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ContactFormModal;
