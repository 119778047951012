import StartRating from "components/StartRating/StartRating";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage"
import { useEffect, useState } from "react";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { base_url } from "constant/constant";
import moment from 'moment';

export interface PayPageProps {
  className?: string;
}
export interface BookingDetails {
  _id:any;
  createdAt:string;
  city: string;
  city_tax: string;
  gross_amount:any; 
  cleaning_fees: string; 
  number_of_person: number;
  payment_date: string; 
  rent:string;
  start_date:any;
  end_date:any;
  property_id: {
   title:string;
   address:string;
   bedrooms:string;
   bathrooms:string;
   toilets:string;
   image:string;
  };
};

const FailedPayPage: FC<PayPageProps> = ({ className = "" }) => {

  const [searchParams] = useSearchParams();
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [bookingData, setBookingData] = useState<BookingDetails>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    if (sessionId) {
      axios.get(`${base_url}/stripe/payment-status?session_id=${sessionId}`)
        .then((response) => {
          console.log(response.data.session.payment_method_types)
          setBookingData(response.data.bookingData)
          setPaymentStatus(response.data.session.payment_method_types[0]);
          setLoading(false);
        })
        .catch((error: any) => {
          console.error("Error fetching payment status:", error);
          setLoading(false);
        });
    } else {
      // setPaymentStatus({ error: "Session ID is missing" });
      setLoading(false);
    }
  }, [searchParams]);

  const getImageVariantUrl = (baseUrl:string, variantSuffix:string) => {
    return baseUrl?.replace(/(\.[\w\d_-]+)$/i, `${variantSuffix}$1`);
};

const handleImageError = (e:any, item:any) => {
  e.target.onerror = null; 
  e.target.src = item;
}
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold text-[red]">
        Payment failed 😞 !
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Your booking</h3>
          <div className="flex flex-col sm:flex-row sm:items-center">
            <div className="flex-shrink-0 w-full sm:w-40">
              <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                <NcImage src={bookingData?.property_id?.image} />
              </div>
            </div>
            <div className="pt-5  sm:pb-5 sm:px-5 space-y-3">
              <div>
                <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
                  {/* Hotel room in Tokyo, Jappan */}
                  {bookingData?.property_id?.address}
                </span>
                <span className="text-base sm:text-lg font-medium mt-1 block">
                  {/* The Lounge & Bar */}
                  {bookingData?.property_id?.title}
                </span>
              </div>
              <span className="block  text-sm text-neutral-500 dark:text-neutral-400">
                {/* 2 beds · 2 baths */}
                {bookingData?.property_id?.bedrooms ??1} Bedrooms · {bookingData?.property_id?.bathrooms ??1} Bathrooms · {bookingData?.property_id?.toilets ??1} Toilets.
              </span>
              {/* <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
              <StartRating /> */}
            </div>
          </div>
          <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Date</span>
                <span className="mt-1.5 text-lg font-semibold">
                  {/* Aug 12 - 16, 2021 */}
               { moment(bookingData?.start_date).format("DD MMM")}   -  {moment(bookingData?.end_date).format("DD MMM")}, {moment(bookingData?.end_date).format("YYYY")}
                </span>
              </div>
            </div>
            <div className="flex-1 p-5 flex space-x-4">
              <svg
                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                  stroke="#D1D5DB"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <div className="flex flex-col">
                <span className="text-sm text-neutral-400">Guests</span>
                <span className="mt-1.5 text-lg font-semibold">{bookingData?.number_of_person} Guests</span>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------ */}
        <div className="space-y-6">
          <h3 className="text-2xl font-semibold">Booking detail</h3>
          <div className="flex flex-col space-y-4">
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Booking link</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
               <a href={`/my-bookings?id=${bookingData?._id}`}>Please click here</a>
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Date</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {/* 12 Aug, 2021 */}
                {moment(bookingData?.createdAt).format("DD MMM, YYYY")}
              </span>
            </div>
            <div className="flex text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Total</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
              € {(Number(bookingData?.gross_amount) + Number(bookingData?.city_tax)).toFixed(2)}
              </span>
            </div>
            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
              <span className="flex-1">Payment method</span>
              <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                {/* Credit card */}
                {paymentStatus}
              </span>
            </div>
          </div>
        </div>
        <div>
          <ButtonPrimary href={`/my-bookings?id=${bookingData?._id}`}>Try another method</ButtonPrimary>
        </div>
      </div>
    );
  };

  const PaymentConfirmation = () => {
    return (

      <div className=" min-h-screen flex justify-center items-center">
        <div className="bg-white rounded-lg p-4 md:p-8 max-w-[100%] sm:max-w-[75%] shadow-lg">
          <a href="/"> 
          <div className="flex justify-center">
            <img style={{ height: "60px" }} height={40} src="logo\europeaLogo.png">
            </img>
          </div>
          </a>
          <div className="my-10">
            <p className="text-gray-700 mb-6 text-center text-[20px] !text-black tracking-[-0.5px]">
              Thank you for your payment of <b>€{bookingData?.rent}</b>. We will send you an email confirmation of your following booking
              shortly.
            </p>
          </div>
          <div className="flex  justify-center items-center grid grid-cols-1  md:grid-cols-2 gap-6 mt-8">
            <div className="sm:flex ">
              <img
                className="w-full sm:w-40 rounded-lg shadow " //object-contain
                src="/pexels-lisa-anna-901356985-19899070.jpg"
                // src={bookingData?.property_id?.image??"/pexels-lisa-anna-901356985-19899070.jpg"}
                alt="Property"
              />
              <div className="pt-3 sm:pt-1 flex-1 ml-3 text-gray-800 justify-center items-center">
                <div>
                <h4 className="font-bold text-lg mb-3">{bookingData?.property_id?.title}</h4>
                <p className="text-sm text-gray-600 mb-4">{bookingData?.property_id?.address}</p>
                <p className="text-[12px] text-gray-600">{bookingData?.property_id?.bedrooms ??1} Bedrooms, {bookingData?.property_id?.bathrooms ??1} Bathrooms, {bookingData?.property_id?.toilets ??1} Toilets.</p>
              </div>
              </div>
            </div>
            <div>
              <div className="grid grid-cols-2">
                {/* Status Item */}
                <div className="flex items-center gap-3 border-r border-gray-300">
                  <div className="w-8 h-8 bg-[linear-gradient(270deg,_#0B5B6F_0%,_rgba(141,_168,_215,_1)_200%)] text-white rounded-full flex justify-center items-center">
                    <i className="la la-calendar"></i>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Checkin Date</p>
                    <p className="text-base font-medium">{moment(bookingData?.start_date).format("YYYY-MM-DD")}</p>
                  </div>
                </div>

                <div className="flex items-center gap-3 -ml-4">
                  <div className="w-8 h-8 bg-[linear-gradient(270deg,_#0B5B6F_0%,_rgba(141,_168,_215,_1)_200%)] text-white rounded-full flex justify-center items-center">
                    <i className="la la-calendar-check"></i>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Checkout Date</p>
                    <p className="text-base font-medium">{moment(bookingData?.end_date).format("YYYY-MM-DD")}</p>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 my-6">
                <div className="flex items-center gap-3">
                  <div className="w-8 h-8 bg-[linear-gradient(270deg,_#0B5B6F_0%,_rgba(141,_168,_215,_1)_200%)] text-white rounded-full flex justify-center items-center">
                    <i className="la la-users"></i>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Guests</p>
                    <p className="text-base font-small">{bookingData?.number_of_person}</p>
                  </div>
                </div>

                <div className="flex items-center gap-3 -ml-4">
                  <div className="w-8 h-8 bg-[linear-gradient(270deg,_#0B5B6F_0%,_rgba(141,_168,_215,_1)_200%)] text-white rounded-full flex justify-center items-center">
                    <i className="la la-credit-card"></i>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Payment Method</p>
                    <p className="text-base font-small">{paymentStatus}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <section
      className="bg-no-repeat bg-cover overflow-hidden relative bg-center"
      // style={{
      //   backgroundImage: `url(/pexels-lisa-anna-901356985-19899070.jpg)`
      // }}
    >
      <div className={`-mt-6  nc-PayPage ${className}`} data-nc-id="PayPage">
        <main className="container mt-11 mb-24 lg:mb-32 ">
          <div className="max-w-6xl mx-auto">
            {renderContent()}
            {/* {PaymentConfirmation()} */}
          </div>
        </main>
      </div>
    </section>
  );
};

export default FailedPayPage;
