import React, { FC, ImgHTMLAttributes, useEffect, useRef, useState } from "react";
import checkInViewIntersectionObserver from "utils/isInViewPortIntersectionObserver";
import PlaceIcon from "./PlaceIcon";

export interface NcImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  containerClassName?: string;
  str?: any;
  imgextension?:any;
}

const NcImage: FC<NcImageProps> = ({
  containerClassName = "",
  str,
  imgextension,
  alt = "nc-imgs",
  src = "", // src should be the original image URL
  className = "object-cover w-full h-full",
  ...args
}) => {
  let isMounted = false;
  const _containerRef = useRef(null);
  let _imageEl: HTMLImageElement | null = null;

  const [__src, set__src] = useState(""); // Thumbnail image state
  const [originalSrc, setOriginalSrc] = useState(""); // Original image state
  const [imageLoaded, setImageLoaded] = useState(false);

  const _initActions = async () => {
    // Ensure the original and thumbnail URLs are set correctly
    set__src(`${src.replace('.jpeg', imgextension?imgextension:'_480.jpeg')}`); // Set the thumbnail URL
    setOriginalSrc(src); // Set the original URL
    _checkInViewPort();
  };

  const _checkInViewPort = () => {
    if (!_containerRef.current) return;
    checkInViewIntersectionObserver({
      target: _containerRef.current,
      options: {
        root: null,
        rootMargin: "0%",
        threshold: 0,
      },
      freezeOnceVisible: true,
      callback: _imageOnViewPort,
    });
  };

  const _imageOnViewPort = () => {
    if (!src) {
      _handleImageLoaded();
      return true;
    }
    _imageEl = new Image();
    if (_imageEl) {
      _imageEl.src = src;
      _imageEl.addEventListener("load", _handleImageLoaded);
    }
    return true;
  };

  const _handleImageLoaded = () => {
    if (!isMounted) return;
    setImageLoaded(true);
  };

  const handleImageError = () => {
    // If the thumbnail image fails to load, use the original image
    set__src(originalSrc); // Fallback to the original image
  };

  useEffect(() => {
    isMounted = true;
    _initActions();
    return () => {
      isMounted = false;
    };
  }, [src]);

  const renderLoadingPlaceholder = () => {
    return (
      <div
        className={`${className} flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500`}
      >
        <div className="h-2/4 max-w-[50%]">
          <PlaceIcon />
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-NcImage ${containerClassName}`}
      data-nc-id="NcImage"
      ref={_containerRef}
    >
      {__src && imageLoaded ? (
        <img
          src={__src} // Display the thumbnail image first
          className={className}
          alt={alt}
          onError={handleImageError} // Handle image error (fallback to original image)
          {...args}
        />
      ) : (
        renderLoadingPlaceholder()
      )}
    </div>
  );
};

export default NcImage;
