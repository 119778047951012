import "./styles/index.css";

import { FC, Fragment, useEffect, useRef, useState } from "react";
import Modal from "./components/Modal";
import type { ListingGalleryImage } from "./utils/types";
import { useLastViewedPhoto } from "./utils/useLastViewedPhoto";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import LikeSaveBtns from "components/LikeSaveBtns";
import { base_url } from "constant/constant";
import axios from "axios";
import Logo from "shared/Logo/Logo";

const PHOTOS: string[] = [
    "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/1320686/pexels-photo-1320686.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/261394/pexels-photo-261394.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    "https://images.pexels.com/photos/2861361/pexels-photo-2861361.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
];

const imgData: any[] = [
    {
        title: "Kitchen",
        images: ["https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=600",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=300&w=700",
            "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/1337753/pexels-photo-1337753.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            "https://images.pexels.com/photos/761963/pexels-photo-761963.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            "https://images.pexels.com/photos/4983184/pexels-photo-4983184.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",]
    },
    {
        title: "Bedroom",
        images: [
            // "https://a0.muscache.com/im/pictures/miso/Hosting-657011800148868945/original/f06d87d2-f20a-4e27-a6c7-85ddc2201ffe.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=720&w=1260",
            "https://a0.muscache.com/im/pictures/miso/Hosting-657011800148868945/original/f06d87d2-f20a-4e27-a6c7-85ddc2201ffe.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=720&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        ]
    },
    {
        title: "Back garden",
        images: ["https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        ]
    },
    {
        title: "Exterior",
        images: ["https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        ]
    },
    {
        title: "Pool",
        images: [
            " https://www.countingcharacters.com/assets/images/resize-image-in-pixels.PNG",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
        ]
    },
    {
        title: "Kitchen",
        images: ["https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=200&w=600",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=300&w=700",
            "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/1337753/pexels-photo-1337753.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            "https://images.pexels.com/photos/761963/pexels-photo-761963.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500",
            "https://images.pexels.com/photos/4983184/pexels-photo-4983184.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",]
    },
    {
        title: "Bedroom",
        images: [
            // "https://a0.muscache.com/im/pictures/miso/Hosting-657011800148868945/original/f06d87d2-f20a-4e27-a6c7-85ddc2201ffe.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=720&w=1260",
            "https://a0.muscache.com/im/pictures/miso/Hosting-657011800148868945/original/f06d87d2-f20a-4e27-a6c7-85ddc2201ffe.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=720&w=1260",
            "https://images.pexels.com/photos/7163619/pexels-photo-7163619.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6527036/pexels-photo-6527036.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6969831/pexels-photo-6969831.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6438752/pexels-photo-6438752.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
            "https://images.pexels.com/photos/6129967/pexels-photo-6129967.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
        ]
    },
]

export const DEMO_IMAGE: ListingGalleryImage[] = [...PHOTOS].map(
    (item, index): ListingGalleryImage => {
        return {
            id: index,
            url: item,
        };
    }
);

export const getNewParam = ({
    paramName = "photoId",
    value,
}: {
    paramName?: string;
    value: string | number;
}) => {
    let params = new URLSearchParams(document.location.search);
    params.set(paramName, String(value));
    return params.toString();
};

interface Props {
    images?: ListingGalleryImage[];
    onClose?: () => void;
    isShowModal: boolean;
}

const CustomListingGalleryImage: FC<Props> = ({
    images = PHOTOS,
    onClose,
    isShowModal,
}) => {
    let [searchParams] = useSearchParams();
    const photoId = searchParams?.get("photoId");
    const navigate = useNavigate();
    const [lastViewedPhoto, setLastViewedPhoto] = useLastViewedPhoto();

    const lastViewedPhotoRef = useRef<HTMLDivElement>(null);
    const url = useLocation().pathname;
    const thisPathname = url.slice(0, -1)
    const sectionRefs = useRef<(HTMLDivElement | null)[]>([]); // Ref for the selected image
    const [mainId, setMainId] = useState(0)

    const [selectedImage, setSelectedImage] = useState([]);
    const mailurl = new URL(window.location.href);
    const id = mailurl.searchParams.get("id");
    const getImageGallary = async () => {

        const response = await axios(`${base_url}/propertyImage/property_image_for_website?property_id=${id}`);
        setSelectedImage(response.data.data)
    }

    useEffect(() => {
        getImageGallary()
    }, [])
    // const handleImageClick = (url: string, index: number) => {
    //     setSelectedImage(url);
    //     if (imageRefs.current[index]) {
    //         imageRefs.current[index].scrollIntoView({ behavior: 'smooth', block: 'start' }); // Scroll to the selected image
    //     }
    // };

    const getImageVariantUrl = (baseUrl: string, variantSuffix: string) => {
        return baseUrl.replace(/(\.[\w\d_-]+)$/i, `${variantSuffix}$1`);
    };

    const handleImageClick = (title: string, id: number) => {
        // Scroll to the specific section
        sectionRefs.current[id]?.scrollIntoView({
            behavior: 'smooth', // For smooth scrolling
            block: 'start', // Align to the top of the section
        });
    };
    //
    useEffect(() => {
        if (lastViewedPhoto && !photoId) {
            lastViewedPhotoRef.current?.scrollIntoView({ block: "center" });
            setLastViewedPhoto(null);
        }
    }, [photoId, lastViewedPhoto, setLastViewedPhoto]);

    const handleClose = () => {
        onClose && onClose();
    };
    const handleImageError = (e: any, item: any) => {
        e.target.onerror = null;
        e.target.src = item;
    }

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    
    const renderContent = () => {
        return (
            <div className=" ">
                {photoId && (
                    <Modal
                        // @ts-ignore
                        images={selectedImage && selectedImage[mainId] && (selectedImage[mainId])?.images}
                        onClose={() => {
                            // @ts-ignore
                            setLastViewedPhoto(photoId);
                            let params = new URLSearchParams(document.location.search);
                            params.delete("photoId");
                            navigate(`${thisPathname}/?${params.toString()}`);
                        }}
                    />
                )}
                <div className="mb-12">
                    <div className="text-[26px] font-medium mb-6 -tracking-[1px]"> Photo tour </div>
                    <div className="flex gap-4 sm:gap-6 sm:flex-wrap overflow-x-auto sm:overflow-x-visible flex-nowrap scrollbar-hide sm:flex-auto">
                        {/* {imgData.map((data: any, id) => {  */}
                        {selectedImage && selectedImage?.map((data: any, id: number) => {
                            return <div className="w-[60%] sm:w-[40%] md:w-[30%] lg:w-[14%] flex-shrink-0" key={id}>
                                <div>
                                    <img onClick={() => handleImageClick(data.title, id)} style={{
                                        cursor: "pointer",
                                        width: "100%",
                                        height: "100px",
                                        objectFit: "cover",
                                        borderRadius: '8px',
                                    }}
                                        onError={(e) => handleImageError(e, data.images[0])}
                                        src={getImageVariantUrl(data.images[0], "_320")}
                                    >
                                    </img>
                                    <p >
                                        {data?.title ?? "All"}
                                    </p>
                                </div>

                            </div>
                        })}
                    </div>
                </div>
                <div className="my-16">
                    {selectedImage && selectedImage?.map((data: any, id: number) => (
                        <div key={id}
                            ref={(el) => (sectionRefs.current[id] = el)}
                            className="mb-8">
                            <div className="flex flex-wrap gap-4"> {/* Container for title and images */}

                                <div className="w-full sm:w-[33%] flex-shrink-0"> {/* Set the width of the title to 25% */}
                                    <div className="font-medium -tracking-[1px] text-[26px] mb-2 sticky top-[80px] py-2">
                                        {data?.title ?? "All"}
                                    </div>
                                </div>
                                <div className="w-full sm:w-[65%]">
                                    {isSafari ? <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                        {data.images?.map((url: string, imgId: number) => (
                                            <div
                                                key={imgId}
                                                onClick={() => {
                                                    setMainId(id);
                                                    const newPathname = getNewParam({ value: imgId });
                                                    navigate(`${thisPathname}/?${newPathname}`);
                                                }}
                                                ref={id === Number(lastViewedPhoto) ? lastViewedPhotoRef : null}
                                                className="relative group cursor-zoom-in mb-0 focus:outline-none"
                                            >
                                                <div className="aspect-[3/2] w-full overflow-hidden rounded-lg">
                                                    <img
                                                        alt="listing gallery"
                                                        className="w-full h-full object-cover transform rounded-lg brightness-90 transition will-change-auto group-hover:brightness-110 focus:outline-none"
                                                        style={{
                                                            transform: "translate3d(0, 0, 0)",
                                                        }}
                                                        src={url}
                                                        width={720}
                                                        height={480}
                                                        sizes="(max-width: 640px) 100vw, (max-width: 1280px) 50vw, 350px"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div> : <div className="columns-1 gap-4 sm:columns-2 xl:columns-2">
                                        {data.images && data.images.map((url: any, imgId: number) => (
                                            <div
                                                key={imgId}
                                                onClick={() => {
                                                    setMainId(id)
                                                    const newPathname = getNewParam({ value: imgId });
                                                    navigate(`${thisPathname}/?${newPathname}`);
                                                }}
                                                ref={id === Number(lastViewedPhoto) ? lastViewedPhotoRef : null}
                                                className="after:content group relative mb-5 block w-full cursor-zoom-in after:pointer-events-none after:absolute after:inset-0 after:rounded-lg after:shadow-highlight focus:outline-none"
                                            >
                                                <img
                                                    alt="chisfis listing gallery"
                                                    className="transform rounded-lg brightness-90 transition will-change-auto group-hover:brightness-110 focus:outline-none"
                                                    style={{
                                                        transform: "translate3d(0, 0, 0)",
                                                    }}
                                                    src={url}
                                                    width={720}
                                                    height={480}
                                                    sizes="(max-width: 640px) 100vw, (max-width: 1280px) 50vw, 350px"
                                                />
                                            </div>
                                        ))}
                                    </div>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </div>
        );
    };

    return (
        <>
            <Transition appear show={isShowModal} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={handleClose}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-white" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="sticky z-10 top-0 p-4 xl:px-10 flex items-center justify-between bg-white">
                            <button
                                className="focus:outline-none focus:ring-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-neutral-100"
                                onClick={handleClose}
                            >
                                <ArrowSmallLeftIcon className="w-6 h-6" />
                            </button>
                            {/* <LikeSaveBtns /> */}
                            <Logo className="pr-4" />
                        </div>

                        <div className="flex min-h-full items-center justify-center sm:p-4 pt-0 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-5"
                                enterTo="opacity-100 translate-y-0"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-5"
                            >
                                <Dialog.Panel className="w-full max-w-[1150px] mx-auto transform p-4 pt-0 text-left transition-all ">
                                    {renderContent()}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default CustomListingGalleryImage;
