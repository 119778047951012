import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ContactFormModal from "./ContactForm";
import { Alert, Snackbar } from "@mui/material";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

type FormValues = {
  name: string;
  email: string;
  phone: string;
  message: string;
  isChecked: boolean;
};

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "#", label: "Installation" },
      { href: "#", label: "Release Notes" },
      { href: "#", label: "Upgrade Guide" },
      { href: "#", label: "Browser Support" },
      { href: "#", label: "Editor Support" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "#", label: "Design features" },
      { href: "#", label: "Prototyping" },
      { href: "#", label: "Design systems" },
      { href: "#", label: "Pricing" },
      { href: "#", label: "Security" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "#", label: "Best practices" },
      { href: "#", label: "Support" },
      { href: "#", label: "Developers" },
      { href: "#", label: "Learn design" },
      { href: "#", label: "Releases" },
    ],
  },
  {
    id: "4",
    title: "Community",
    menus: [
      { href: "#", label: "Discussion Forums" },
      { href: "#", label: "Code of Conduct" },
      { href: "#", label: "Community Resources" },
      { href: "#", label: "Contributing" },
      { href: "#", label: "Concurrent Mode" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    email: "",
    phone: "",
    message: "",
    isChecked: false
  });
  const [open, setOpen] = useState(false)
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const Footer = () => {

    return (
      <div className="py-1">

        <div className="container mx-auto px-4 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
          {/* Left Section: Logo and Links */}
          <div>
            <Logo />
          </div>
          <div className="flex flex-col">
            <h6 className="font-bold mb-3 text-[#0c586e]">Dedicated Agency / Paris and Brussels</h6>
            <ul className="space-y-2">
              <li className="flex items-center text-[#0c586e]">
                <span className="mr-2 text-[#bf8c4c]">▶</span>
                <a
                  href="https://www.europeaforowner.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline"
                > Join Europea as Owner </a>

              </li>
              <li className="flex items-center text-[#0c586e]">
                <span className="mr-2 text-[#bf8c4c]">▶</span>
                <a href="https://www.europea-residences.com/terms-of-use"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline">
                  Terms of Use
                </a>

              </li>
              <li className="flex items-center text-[#0c586e]">
                <span className="mr-2 text-[#bf8c4c]">▶</span> Often asked questions
              </li>
            </ul>
          </div>

          <div>
            <ul className="space-y-2">
              <li className="flex items-center text-[#0c586e]">
                <span className="w-2 h-2 rounded-full bg-gray-400 mr-2"></span>
                ALLIANZ RC Exploitation : N°6288381900
              </li>
              <li className="flex items-center text-[#0c586e]">
                <span className="w-2 h-2 rounded-full bg-gray-400 mr-2"></span>
                SOCAF Protection Host : N°72663813674
              </li>
              <li className="flex items-center text-[#0c586e]">
                <span className="w-2 h-2 rounded-full bg-gray-400 mr-2"></span>
                FR Entity : RENT SARL
              </li>
              <li className="flex items-center text-[#0c586e]">
                <span className="w-2 h-2 rounded-full bg-gray-400 mr-2"></span>
                BEL Entity : EUROPEA RESIDENCES SA
              </li>
            </ul>
          </div>

          {/* Right Section: Contact Button */}
          <div className="text-center">
            <button onClick={() => { setOpen(true) }} className="bg-[#bf8c4c] text-white py-2 px-6 rounded-full">
              CONTACT US
            </button>
          </div>
        </div>

        {/* Footer Bottom Text */}
        <div className="text-center text-[16px] text-gray-500 mt-6">
          © 2025 Europea Residences
        </div>
      </div>
    );
  };
  const handleClose = () => {
    setOpen(false);
    setFormValues({
      name: "",
      email: "",
      phone: "",
      message: "",
      isChecked: false
    })
  }

  return (
    <>
      {!(["/pay-done", "/pay-failed", "/my-bookings",'/terms-of-use','/travel-terms-of-use'].includes(window.location.pathname)) && (
        <footer className=" border-t border-mediumgray mt-6">
          <ContactFormModal setSnackbarSeverity={setSnackbarSeverity}
            setSnackbarMessage={setSnackbarMessage} setSnackbarOpen={setSnackbarOpen} formValues={formValues} setFormValues={setFormValues} isOpen={open} onClose={handleClose} />

          {/* <div className="container">
            <div className="flex flex-wrap items-center justify-between">
              <div className="text-center md:text-start sm:mb-[20px] flex sm:justify-center col-span-1 md:col-span-3">
                <Logo />
              </div>
              <div className="text-center md:text-end sm:mb-[20px] col-span-1 md:col-span-6">
                <p>© 2025 Europea Residences</p>
              </div>
              <div className="flex md:text-start sm:justify-center col-span-1 md:col-span-3">
                <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-row lg:space-y-2.5 lg:items-start" />
              </div>
            </div>
          </div>
          <br /> */}
          {/* {Footer()} */}
          <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32 ">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <div className="space-y-8">
                <Logo />
                <ul className="mt-6 space-y-4">
                  <li>
                    <b className="text-[#0c586e]">Travel and Residence Management
                    </b>
                  </li>
                  <li>
                    <h3 className="text-sm/6 font-semibold text-gray-900 dark:text-neutral-300 !text-[#0c586e]">
                      Paris and Brussels
                      <br/>
                      Hotel - Aparthotel - Airbnb
                   </h3>
                  </li>
                  <li>
                  <p className="text-[#000]"> Want to get in touch? {" "}
                    <button onClick={() => { setOpen(true) }} className="underline text-[#000]">Contact Us </button>
                  </p>
                  </li>
                  </ul>
                 
              </div>
              <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                <div className="flex justify-center">
                  <div>
                    <h3 className="text-sm/6 font-semibold text-gray-900 dark:text-neutral-300 !text-[#0c586e]">
                      Legal details
                    </h3>
                    <ul className="mt-6 space-y-4">
                      <li>
                        <a className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                          ALLIANZ RC Exploitation : N°6288381900
                        </a>
                      </li>
                      <li>
                        <a className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                          SOCAF Protection Host : N°SP26976-2025
                          {/* //N°72663813674 */}
                        </a>
                      </li>
                      <li>
                        <a className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                          FR Entity : RENT SARL
                        </a>
                      </li>
                      <li>
                        <a className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                          BEL Entity : EUROPEA RESIDENCES SA
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex justify-center">
                  <div >
                    <h3 className="text-sm/6 font-semibold text-gray-900 dark:text-neutral-300 !text-[#0c586e]">
                      Important links
                    </h3>
                    <ul className="mt-6 space-y-4">
                      <li>
                        <a href="https://www.europeaforowner.com"
                          target="_blank" className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                          Join Europea as Owner / Partner
                        </a>
                      </li>
                      <li>
                        <a href="/travel-terms-of-use" target="_blank" className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                          Online Travel Terms of Use
                        </a>
                      </li>
                      <li>
                        <a href="/terms-of-use" target="_blank" className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                         Residence Terms of Use
                        </a>
                      </li>
                      <li>
                        <a className="text-sm/6 text-gray-600 hover:text-gray-900 dark:text-neutral-400 !text-[#000]">
                          Often asked questions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 border-t border-gray-900/10 pt-8 dark:border-gray-700 sm:mt-20 lg:mt-24">
              <p className="text-sm/6 text-gray-600 dark:text-neutral-400">
                © 2025 Europea, Inc. All rights reserved.
              </p>
            </div>
          </div>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </footer >

      )}

    </>
  );
};

export default Footer;
