import React, { useEffect, useState } from "react";
import { FC } from "react";
import useOutsideAlerter from "hooks/useOutsideAlerter";

export interface CustomFeesProps {
  className?: string;
  fieldClassName?: string;
  autoFocus?: boolean;
  cleaningFees?: any;
  managementFees?: any;
  communityFees?: any
};

const CustomFees: FC<CustomFeesProps> = ({
  fieldClassName = "[ nc-hero-field-padding--small ]",
  className = "",
  autoFocus = false,
  cleaningFees,
  managementFees,
  communityFees
}) => {
  const refContainer = React.createRef<HTMLDivElement>();
  const [isOpen, setIsOpen] = useState(false);
  useOutsideAlerter(refContainer, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(autoFocus);
  }, [autoFocus]);

  return (
    <div className={`flex z-10 relative ${className}`} ref={refContainer}>
      <div
        className={`flex z-10 relative flex-1 text-left justify-between items-center focus:outline-none cursor-pointer ${isOpen ? "nc-hero-field-focused--2" : ""
          }`}
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
        >
          <div >
            <span className="block font-semibold underline">
              Fees            </span>
          </div>
        </div>
      </div>

      {isOpen && (
        <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -left-0.5 right-10 bg-white dark:bg-neutral-800"></div>
      )}

      {isOpen && (
        // <div className="absolute right-0 z-30 w-full sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">

        <div className="absolute md:right-0 z-30 w-full min-w-[200px] sm:min-w-[340px] max-w-sm bg-white dark:bg-neutral-800 top-full mt-3 py-5 sm:py-6 px-4 sm:px-8 rounded-3xl shadow-xl">
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <h3 className="text-lg font-medium text-black"> Fees </h3>
              <button
                onClick={() => setIsOpen(false)}
                className="text-sm text-neutral-600 hover:text-neutral-900 dark:text-neutral-400 dark:hover:text-neutral-200"
                aria-label="Close"
              >
                ✖
              </button>
            </div>
            <ul className="space-y-2">
              <li
                key={1}
                className="flex justify-between text-sm text-neutral-600 dark:text-neutral-300"
              >
                <span>Cleaning fee</span>
                <span> € {cleaningFees}</span>
              </li>
              <li
                key={1}
                className="flex justify-between text-sm text-neutral-600 dark:text-neutral-300"
              >
                <span>Community fee</span>
                <span> € {communityFees}</span>
              </li>
              <li
                key={1}
                className="flex justify-between text-sm text-neutral-600 dark:text-neutral-300"
              >
                <span>Management fee</span>
                <span> € {managementFees}</span>
              </li>


            </ul>
            <div className="border-t mt-4 pt-4 flex justify-between text-sm font-medium">
              <span>Total</span>
              <span>€ {Number(cleaningFees || 0) + Number(communityFees || 0) + Number(managementFees || 0)}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomFees;
