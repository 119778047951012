import { Transition } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StayCard from "components/StayCard/StayCard";
import { CarDataType, ExperiencesDataType, StayDataType } from "data/types";
import React, { FC, Fragment, useEffect, useRef } from "react";
import { useState } from "react";
import { Dialog, Tab } from "@headlessui/react";
import PropertyCardMap from "components/listingCardOfProperty/cardMap"

export interface AnyPropertyReactComponentProps {
  className?: string;
  listing?: any;
  experiences?: ExperiencesDataType;
  car?: CarDataType;
  isSelected?: boolean;
  lat: number;
  lng: number;
  base_price:number;
  handleSetCenterLocation: (lat: number, lng: number) => void;
}

const AnyPropertyReactComponentProps: FC<AnyPropertyReactComponentProps> = ({
  className = "",
  listing,
  car,
  experiences,
  isSelected,
  lat,
  lng,
  handleSetCenterLocation
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e: any, lat: number, lng: number) => {
    e.preventDefault();
    handleSetCenterLocation(lat+ 0.025, lng);
    setIsOpen(true);
  };
  const popoverRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        setIsOpen(false); // Close popover
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  return (
    <div
      className={`nc-AnyReactComponent relative  ${className}`}
      data-nc-id="AnyReactComponent"
      ref={popoverRef}
      onClick={(e: any) => handleClick(e, lat, lng)}
    // onMouseEnter={() => setIsOpen(true)}
    // onMouseLeave={() => setIsOpen(false)}
    >
      <span
        style={{
          width:'100px',
          padding: '6px 15px',
          fontSize: '16px',
          fontWeight: '400',
          borderRadius: '25px',
          boxShadow: '0 8px 10px rgba(0, 0, 0, 0.2), 0 8px 17px rgba(0, 0, 0, 0.2)'
        }}
        className={`flex px-2 py-1 rounded-lg bg-white dark:bg-neutral-900 text-sm font-semibold items-center justify-center min-w-max shadow-lg hover:bg-[#BF8C4C] hover:text-white dark:hover:bg-[#BF8C4C] dark:hover:text-neutral-900 transition-colors
           ${isSelected ? "!bg-[#BF8C4C] text-white dark:!bg-[#BF8C4C] dark:text-neutral-900"
            : "" }
            `}
      >
        €{Number(listing?.avgRatePerDay ?? listing?.base_price).toFixed(2)}
      </span>
      <Transition
        
        show={isOpen}
        as={Fragment}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {/* <Dialog
          as="div"
          onClose={() => setIsOpen(false)}
        > */}
          <div className="absolute z-50 bottom-full pb-3 -left-12 w-[260px] aspect-w-1">
            {listing && (
              // <StayCard size="small" data={listing} className="shadow-2xl" />
              <PropertyCardMap data={listing} className="shadow-2xl" />
              
            )}
          </div>
        {/* </Dialog> */}
      </Transition>

    </div>
  );
};

export default AnyPropertyReactComponentProps;
